import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem, ListItemButton,
  ListItemText,
  TextField,
  Typography
} from "@mui/material";
import {DW_SM, PD_MD} from "./dimens";
import {Action} from "./types";
import {createInput, InputSpec} from "./BaseFragment";
import React, {ReactElement, useState} from "react";
import {BaseApp} from "./BaseApp";
import {sleep} from "./sleep";
import {colorRed} from "./colors";

export function ActionsDialogContent(props: {
  actions: Action[],
  title?: string,
  inputSpec?: InputSpec,
  preventHideOnAction?: boolean,
  args?: any[]
}) {
  let input = createInput(props.inputSpec);
  return <Box display="flex" flexDirection="column" style={{width: DW_SM}}>
    {input}
    {props.title ? <>
        <DialogTitle>{props.title}</DialogTitle>
        <Divider/>
      </>
      : null}
    <List>
      {props.actions.map((action, index) => (
        <ListItemButton style={{color: action.destructive ? colorRed : null}} onClick={(e) => {
          async function onClick() {
            if (!props.preventHideOnAction) {
              BaseApp.CONTEXT.hideDialog();
              await sleep(100);
            }
            action.onClick(e, props.args);
          }

          onClick();
        }} key={index}>
          <ListItemText primary={action.text}/>
        </ListItemButton>
      ))}
    </List>
  </Box>;
}

export function CustomDialogContent(props: {
  title: string,
  customView: ReactElement,
  action: Action,
  altAction?: Action,
  cancelable?: boolean
}) {
  return <Box display="flex" flexDirection="column" style={{width: DW_SM}}>
    <DialogTitle>{props.title}</DialogTitle>
    <Divider/>
    <DialogContent style={{padding: PD_MD}}>
      {props.customView}
    </DialogContent>
    <DialogActions>
      {Boolean(props.cancelable) ? <Button onClick={() => BaseApp.CONTEXT.hideDialog()}>Cancel</Button>
        : null}
      {props.altAction ? <Button onClick={(e) => {
          BaseApp.CONTEXT.hideDialog();
          props.altAction.onClick(e);
        }}>
          {props.altAction.text}
        </Button>
        : null}
      <Button onClick={(e) => {
        BaseApp.CONTEXT.hideDialog();
        props.action.onClick(e);
      }}>
        {props.action.text}
      </Button>
    </DialogActions>
  </Box>;
}

export function TextDialogContent(props: {
  title: string,
  text: string,
  action: Action,
  altAction?: Action,
  cancelable?: boolean
}) {
  return <CustomDialogContent
    title={props.title} customView={<Typography>{props.text}</Typography>}
    action={props.action} altAction={props.altAction} cancelable={props.cancelable}/>;
}

export function TextInputDialogContent(props: {
  title: string,
  text: string,
  textLabel: string,
  action: Action,
  cancelable?: boolean
}) {
  const [value, setValue] = useState("");
  return <Box display="flex" flexDirection="column" style={{width: DW_SM}}>
    <DialogTitle>{props.title}</DialogTitle>
    <Divider/>
    <DialogContent style={{padding: PD_MD}}>
      <Typography>{props.text}</Typography>
      <TextField
        autoFocus
        margin="dense"
        value={value}
        onChange={event => setValue(event.target.value)}
        label={props.textLabel}
        type="text"
        fullWidth
      />
    </DialogContent>
    <DialogActions>
      {Boolean(props.cancelable) ? <Button onClick={() => this.hideDialog()}>Cancel</Button>
        : null}
      <Button onClick={(e) => {
        BaseApp.CONTEXT.hideDialog();
        props.action.onClick(e, value);
      }}>
        {props.action.text}
      </Button>
    </DialogActions>
  </Box>;
}
