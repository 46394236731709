import {$KTS, BaseListItem, BaseListItemsLoader, KeyTextStrings} from "../../shared/types";
import {JsonObject} from "../../shared/json/json-object";
import {JSON_OBJECT} from "../../shared/json/helpers";
import {md5_uuid} from "../../shared/md5";
import {getMemberAuth} from "../../shared/auth";
import {JsonProperty} from "../../shared/json/json-property";
import {FormGen} from "../../shared/formgen";

@JsonObject()
export class Folder extends BaseListItem {

  @FormGen({name: "Title", type: "string", description: "A name for this folder."})
  @JsonProperty()
  title: string;

  @FormGen({name: "Description", type: "string", description: "What this folder is about."})
  @JsonProperty()
  description: string;

  static createNew(): Folder {
    return new Folder(md5_uuid(), getMemberAuth().getMemberId(), Date.now());
  }

  constructor(id: string, creator: string, created: number) {
    super(id, creator, created);
  }
}

export class Folders extends BaseListItemsLoader<Folder> {

  private static instance: Folders;

  static getInstance() {
    if (!this.instance) {
      this.instance = new Folders();
    }
    return this.instance;
  }

  protected basePath(): string {
    return "form_folders";
  }

  protected deserializeItem(value: any): Folder {
    return JSON_OBJECT.deserializeObject(value, Folder);
  }

  protected serializeItem(item: Folder): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: Folder, item2: Folder): number {
    return item2.created - item1.created;
  }
}