import {
  $KTM,
  $KTS,
  BaseListItem,
  BaseListItemsLoader,
  ColorValue,
  KeyMetadatas,
  KeyTextStrings,
  ListKeysLoader
} from "../../shared/types";
import {JsonObject} from "../../shared/json/json-object";
import {JSON_OBJECT, Type} from "../../shared/json/helpers";
import {md5_uuid} from "../../shared/md5";
import {getMemberAuth} from "../../shared/auth";
import {JsonProperty} from "../../shared/json/json-property";
import {
  black,
  colorDarkGreen,
  colorDarkPink,
  colorPastelGreen,
  colorPastelRed,
  lightGray,
  mediumGray,
  white
} from "../../shared/colors";
import {SvgIcon} from "@mui/material";
import {
  BallotOutlined,
  CalendarTodayOutlined,
  DateRangeOutlined,
  EmailOutlined,
  ImageOutlined,
  InsertLinkOutlined,
  LooksOneOutlined,
  PhoneOutlined,
  ScheduleOutlined,
  TitleOutlined
} from "@mui/icons-material";
import {FormGen} from "../../shared/formgen";

export enum FormQuestionItemType {
  CHOICE = "choice",
  TEXT = "text",
  DATE = "date",
  DATE_RANGE = "date_range",
  PHONE = "phone",
  EMAIL = "email",
  NUMBER = "number",
  LINK = "link",
  IMAGE = "image",
  DURATION = "duration",
}

type FormQuestionFieldType = {
  displayName: string,
  type: FormQuestionItemType,
  iconType: typeof SvgIcon,
}

export const FORM_QUESTION_FIELD_TYPES: FormQuestionFieldType[] = [
  {
    displayName: "Choice",
    type: FormQuestionItemType.CHOICE,
    iconType: BallotOutlined,
  },
  {
    displayName: "Text",
    type: FormQuestionItemType.TEXT,
    iconType: TitleOutlined,
  },
  {
    displayName: "Date",
    type: FormQuestionItemType.DATE,
    iconType: CalendarTodayOutlined,
  },
  {
    displayName: "Date range",
    type: FormQuestionItemType.DATE_RANGE,
    iconType: DateRangeOutlined,
  },
  {
    displayName: "Phone",
    type: FormQuestionItemType.PHONE,
    iconType: PhoneOutlined,
  },
  {
    displayName: "Email",
    type: FormQuestionItemType.EMAIL,
    iconType: EmailOutlined,
  },
  {
    displayName: "Number",
    type: FormQuestionItemType.NUMBER,
    iconType: LooksOneOutlined,
  },
  {
    displayName: "Link",
    type: FormQuestionItemType.LINK,
    iconType: InsertLinkOutlined,
  },
  {
    displayName: "Image",
    type: FormQuestionItemType.IMAGE,
    iconType: ImageOutlined,
  },
  {
    displayName: "Duration",
    type: FormQuestionItemType.DURATION,
    iconType: ScheduleOutlined,
  },
];

@JsonObject()
export class FormQuestionItem extends BaseListItem {

  @JsonProperty()
  formId: string;

  @JsonProperty()
  type: FormQuestionItemType;

  @JsonProperty()
  required: boolean;

  @JsonProperty()
  data: any;

  static createNewItemType(creator: string, created: number, formId: string, type: FormQuestionItemType) {
    const item = new FormQuestionItem(md5_uuid(), creator, created, formId, type);
    switch (type) {
      case FormQuestionItemType.CHOICE:
        item.data = JSON_OBJECT.serializeObject(new FormQuestionItemChoiceData());
        break;
      case FormQuestionItemType.TEXT:
        item.data = JSON_OBJECT.serializeObject(new FormQuestionItemTextData());
        break;
    }
    return item;
  }

  constructor(id: string, creator: string, created: number, formId: string, type: FormQuestionItemType) {
    super(id, creator, created);
    this.formId = formId;
    this.type = type;
  }

  readData<T>(dataType: Type<T>): T {
    return JSON_OBJECT.deserializeObject(this.data, dataType) as T;
  }
}

@JsonObject()
export class BaseFormQuestionItemData {

  @FormGen({name: "Question", type: "string"})
  @JsonProperty()
  text: string = "Question";
}

@JsonObject()
export class FormQuestionItemChoiceData extends BaseFormQuestionItemData {

}

@JsonObject()
export class FormQuestionItemTextData extends BaseFormQuestionItemData {
}

export class FormQuestionItems extends BaseListItemsLoader<FormQuestionItem> {

  constructor(readonly formId: string) {
    super({shared: true});
  }

  protected basePath(): string {
    return "form_question_items/" + this.formId;
  }

  protected deserializeItem(value: any): FormQuestionItem {
    return JSON_OBJECT.deserializeObject(value, FormQuestionItem);
  }

  protected serializeItem(item: FormQuestionItem): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: FormQuestionItem, item2: FormQuestionItem): number {
    return item1.created - item2.created;
  }
}

@JsonObject()
export class FormAnswerItem extends BaseListItem {

  @JsonProperty()
  formId: string;

  @JsonProperty()
  questionItemId: string;

  @JsonProperty()
  type: FormQuestionItemType;

  @JsonProperty()
  required: boolean;

  @JsonProperty()
  data: any;

  constructor(id: string, creator: string, created: number, formId: string, questionItemId: string, type: FormQuestionItemType) {
    super(id, creator, created);
    this.formId = formId;
    this.questionItemId = questionItemId;
    this.type = type;
  }

  readData<T>(dataType: Type<T>): T {
    return JSON_OBJECT.deserializeObject(this.data, dataType) as T;
  }
}

@JsonObject()
export class BaseFormAnswerItemData {
}

@JsonObject()
export class FormAnswerItemChoiceData extends BaseFormAnswerItemData {

}

@JsonObject()
export class FormAnswerItemTextData extends BaseFormAnswerItemData {

  @JsonProperty()
  text: string;
}

export class FormAnswerItems extends BaseListItemsLoader<FormAnswerItem> {

  constructor(readonly formId: string, readonly answerId: string) {
    super({shared: true});
  }

  protected basePath(): string {
    return "form_answer_items/" + this.formId + "/" + this.answerId;
  }

  protected deserializeItem(value: any): FormAnswerItem {
    return JSON_OBJECT.deserializeObject(value, FormAnswerItem);
  }

  protected serializeItem(item: FormAnswerItem): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: FormAnswerItem, item2: FormAnswerItem): number {
    return item1.created - item2.created;
  }
}

export class FormAnswerIds extends ListKeysLoader {

  constructor(readonly formId: string) {
    super({shared: true});
  }

  protected basePath(): string {
    return "form_answer_items/" + this.formId;
  }
}

export enum FormsViewType {
  FORMS_MYFORMS = "forms_myforms",
}

export const FORMS_VIEW_TYPE_KTS = new KeyTextStrings([
  $KTS(FormsViewType.FORMS_MYFORMS, "Forms - My forms"),
]);

export enum FormStatus {
  DRAFT = "draft",
  PUBLISHED = "published",
  PAUSED = "paused",
  ARCHIVED = "archived",
}

@JsonObject()
export class FormProperties {

  @FormGen({name: "Theme color", type: "color"})
  @JsonProperty()
  themeColor: string;

  // @FormGen({name: "Header image", type: "file", fileMimeTypes: [MimeTypeMap.MimeType_image_png, MimeTypeMap.MimeType_image_jpeg]})
  // @JsonProperty()
  // headerImage: string;
}

export const FORM_STATUS_KTS = new KeyTextStrings([
  $KTS(FormStatus.DRAFT, "Draft"),
  $KTS(FormStatus.PUBLISHED, "Published"),
  $KTS(FormStatus.PAUSED, "Paused"),
  $KTS(FormStatus.ARCHIVED, "Archived"),
]);

export const FORM_STATUS_KTM_COLORS = new KeyMetadatas<ColorValue>([
  $KTM<ColorValue>(FormStatus.DRAFT, new ColorValue(black, lightGray)),
  $KTM<ColorValue>(FormStatus.PUBLISHED, new ColorValue(colorDarkGreen, colorPastelGreen)),
  $KTM<ColorValue>(FormStatus.PAUSED, new ColorValue(colorDarkPink, colorPastelRed)),
  $KTM<ColorValue>(FormStatus.ARCHIVED, new ColorValue(white, mediumGray)),
]);

@JsonObject()
export class Form extends BaseListItem {

  @JsonProperty()
  folderId: string;

  @FormGen({name: "Title", type: "string"})
  @JsonProperty()
  title: string;

  @JsonProperty()
  status: string = FormStatus.DRAFT;

  @FormGen({name: "Description", type: "string"})
  @JsonProperty()
  description: string;

  @JsonProperty()
  favorite: boolean;

  @JsonProperty()
  properties: FormProperties = new FormProperties();

  static createNew(): Form {
    return new Form(md5_uuid(), getMemberAuth().getMemberId(), Date.now());
  }

  constructor(id: string, creator: string, created: number) {
    super(id, creator, created);
  }
}

export class Forms extends BaseListItemsLoader<Form> {

  private static instance: Forms;

  static getInstance() {
    if (!this.instance) {
      this.instance = new Forms();
    }
    return this.instance;
  }

  constructor() {
    super({shared: true});
  }

  protected basePath(): string {
    return "forms";
  }

  protected deserializeItem(value: any): Form {
    return JSON_OBJECT.deserializeObject(value, Form);
  }

  protected serializeItem(item: Form): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: Form, item2: Form): number {
    return 0;
  }
}