import {DialogFragment, PageFragment, PageFragmentProps} from "shared/PageFragment";
import {FormGenContainer, FormGenContainerMode} from "shared/FormGenContainer";
import {DW_LG} from "shared/dimens";
import {App} from "app/App";
import {DIALOG_FLAG_DISABLE_BACKDROP_CLICK, DIALOG_FLAG_SHOW_CLOSE} from "shared/BaseApp";
import React from "react";
import {StyledBoxColumn} from "../../shared/StyledComponents";
import {PathProps} from "../../index";
import {Folder, Folders} from "./types";
import {StringUtil} from "../../shared/string_util";

export function onManageFolder(path: PathProps, folder: Folder) {
  path.navigate(StringUtil.joinNotEmptyNull("/", "/folders", folder?.id));
}

export class EditFolderHelper {

  static addFolder() {
    App.CONTEXT.showDialog(
      {flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_DISABLE_BACKDROP_CLICK},
      () => <EditFolderFragment onFolderSaved={folder => App.CONTEXT.hideDialog()}/>);
  }

  static editFolder(path: PathProps, folder: Folder) {
    App.CONTEXT.showDialog(
      {flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_DISABLE_BACKDROP_CLICK},
      () => <EditFolderFragment path={path} edit={folder} onFolderSaved={folder => App.CONTEXT.hideDialog()}/>);
  }
}

type EditFolderFragmentProps = PageFragmentProps & {
  edit?: Folder,
  existing?: boolean,
  onFolderSaved: (folder: Folder) => void,
}

class EditFolderFragment extends DialogFragment<EditFolderFragmentProps> {

  private readonly folders = Folders.getInstance();
  private readonly folder = this.props.edit ? this.props.edit : Folder.createNew();

  protected onCreateState() {
    return {
      ...super.onCreateState(),
      title: this.props.edit ? "Edit" : "Add",
    };
  }

  protected styleFlags(): number {
    return PageFragment.STYLE_ACTION_PANEL_TYPE_BUTTON_FLAG | PageFragment.STYLE_TOOLBAR_TYPE_FLAG;
  }

  protected getActionButtonText(): string {
    return this.props.edit ? "Save" : "Add";
  }

  onActionButtonClicked() {
    this.addFolder().then(() => this.props.onFolderSaved(this.folder));
  }

  private async addFolder() {
    await this.folders.addListItem(this.folder);
  }

  renderContent(): React.ReactElement | null {
    return <StyledBoxColumn style={{display: "flex", width: DW_LG}}>
      <FormGenContainer
        mode={this.props.edit ? FormGenContainerMode.EDIT : FormGenContainerMode.CREATE}
        content={this.folder}/>
    </StyledBoxColumn>;
  }
}
