import {DialogFragment, DialogFragmentProps, DialogFragmentState, PageFragment} from "../../shared/PageFragment";
import React, {ReactElement} from "react";
import {Form} from "./types";
import {StyledBoxColumn, StyledBoxRow, StyledVerticalDivider} from "../../shared/StyledComponents";
import {TabInfo} from "../../shared/TabsContainer";
import {CodeOutlined, ContentCopyOutlined, LinkOutlined} from "@mui/icons-material";
import {IconButton, TextField, Tooltip, Typography} from "@mui/material";
import {PD_MD} from "../../shared/dimens";
import {BaseApp} from "../../shared/BaseApp";

export type FormPublishViaFragmentProps = DialogFragmentProps & {
  form: Form,
}

type FormPublishViaFragmentState = DialogFragmentState & {
  embedWidth: number,
  embedHeight: number,
}

enum FormPublishViaType {
  LINK = "link",
  EMBED = "embed",
}

const TABS: TabInfo<FormPublishViaType>[] = [
  {
    type: FormPublishViaType.LINK,
    text: "Link",
    iconType: LinkOutlined,
  },
  {
    type: FormPublishViaType.EMBED,
    text: "Embed",
    iconType: CodeOutlined,
  },
];

export class FormPublishViaFragment extends DialogFragment<FormPublishViaFragmentProps, FormPublishViaFragmentState> {

  protected onCreateState(): FormPublishViaFragmentState {
    return {
      ...super.onCreateState(),
      selectedToolbarTabId: FormPublishViaType.LINK,
      embedWidth: 640,
      embedHeight: 760,
    };
  }

  protected styleFlags(): number {
    return PageFragment.STYLE_TOOLBAR_TYPE_FLAG;
  }

  getToolbarTabs(): TabInfo<any>[] {
    return TABS;
  }

  renderContent(): React.ReactElement {
    switch (this.state.selectedToolbarTabId) {
      case FormPublishViaType.LINK: {
        const text = "https://forms.fyneapps.com/v/" + this.props.form.id;
        return this.renderInContainer("Link",
          <>
            {this.renderCopyableText(text)}
          </>);
      }
      case FormPublishViaType.EMBED: {
        const text = "<iframe src=\"https://forms.fyneapps.com/v/" + this.props.form.id + "?embedded=true\" width=" + "\"" + this.state.embedWidth + "\"" + " height=\"" + this.state.embedHeight + "\" frameborder=\"0\" marginheight=\"0\" marginwidth=\"0\">Loading…</iframe>";
        return this.renderInContainer("Embed",
          <>
            {this.renderCopyableText(text)}
            <StyledBoxRow style={{alignItems: "center"}}>
              <Typography>Width: </Typography>
              <TextField size="small" inputMode="numeric" value={this.state.embedWidth}
                         onChange={event => this.setState({embedWidth: Number.parseInt(event.target.value)})}/>
              <StyledVerticalDivider/>
              <Typography>Height: </Typography>
              <TextField size="small" inputMode="numeric" value={this.state.embedHeight}
                         onChange={event => this.setState({embedHeight: Number.parseInt(event.target.value)})}/>
            </StyledBoxRow>
          </>);
      }
    }
    return null;
  }

  private renderInContainer(title: string, element: ReactElement): ReactElement {
    return <StyledBoxColumn style={{padding: PD_MD}}>
      <Typography variant="h6">{title}</Typography>
      {element}
    </StyledBoxColumn>;
  }

  private renderCopyableText(text: string): ReactElement {
    return <StyledBoxRow style={{alignItems: "center"}}>
      <TextField size="small" disabled value={text} style={{flexGrow: 1}}/>
      <Tooltip title={"Copy"}>
        <IconButton onClick={() => {
          window.navigator.clipboard.writeText(text);
          BaseApp.CONTEXT.showToast("Copied!");
        }}>
          <ContentCopyOutlined/>
        </IconButton>
      </Tooltip>
    </StyledBoxRow>;
  }
}