import {
  Auth,
  createUserWithEmailAndPassword,
  getAdditionalUserInfo,
  getAuth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  UserCredential
} from "@firebase/auth";
import React, {ReactElement, useState} from "react";
import {Box, Button, ButtonBase, IconButton, InputAdornment, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import {BORDER_RADIUS, DIVIDER, PD_MD} from "./dimens";
import {colorRed, lightGray} from "./colors";
import {get, set} from "@firebase/database";
import {UserCache} from "./types";
import {BaseApp, getProvisioningId, renderStamp, setLoginCredentials, setProvisioningId} from "./BaseApp";
import {HtmlFragment} from "./HtmlFragment";
import {RefreshOutlined, Visibility, VisibilityOff} from "@mui/icons-material";
import {User} from "./entities";
import {validateEmail} from "./text_util";
import {PROVISIONING_CODE_MAX_LENGTH, PROVISIONING_CODE_MIN_LENGTH} from "./constants";
import {md5} from "./md5";
import {dbRef, ProvisioningContext, SystemProvisioningIds} from "./database";
import {JSON_OBJECT} from "./json/helpers";
import {StyledBoxColumn} from "shared/StyledComponents";
import {FirebaseOptions} from "firebase/app";

enum View {
  VERIFY_CUSTOMER,
  SETUP,
  LOGIN,
  SIGNUP,
}

export async function signupWithEmailAndPassword(auth: Auth, firstname: string, lastname: string, email: string, password: string, isEmployee: boolean, isTest: boolean, setError: (error: string) => void) {
  if (!validateEmail(email)) {
    setError("Invalid email: " + email + ". Please enter a valid email.");
    return;
  }
  if (password.length < 8) {
    setError("Please enter a password with at least 8 characters.");
    return;
  }
  await createUserWithEmailAndPassword(auth, email, password)
    .then((value: UserCredential) => {
      const user = new User(
        value.user.uid,
        firstname,
        lastname,
        null,
        email,
        Date.now(),
        null,
        isEmployee,
        isTest);
      const object = JSON_OBJECT.serializeObject(user);
      return set(dbRef("users/" + value.user.uid), object).then(() => user);
    })
    .then(user => UserCache.getInstance().setUser(user.uid, user))
    .then(() => {
      setLoginCredentials({
        email: email,
        password: password,
      });
    })
    .catch(reason => {
      setError("Error signing up. Please try again later.");
    });
}

function SignupFragment(props: { auth: Auth, setView: (view: View) => void }) {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [agree, setAgree] = useState(false);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  function doSignup() {
    signupWithEmailAndPassword(props.auth, firstname, lastname, email, password, false, false, setError)
      .then(() => window.location.href = "/");
  }

  const onKeyDown = event => {
    if (event.keyCode === 13) {
      event.preventDefault();
      doSignup();
    }
  };

  const signupEnabled = email.length > 0 && firstname.length > 0 && password.length > 0 && agree;
  return <Box style={{display: "flex", flexDirection: "column", gap: PD_MD}}>
    <Box style={{display: "flex", gap: PD_MD}}>
      <TextField autoFocus size="small" style={{flexGrow: 1}} placeholder="First name" required onKeyDown={onKeyDown}
                 onChange={event => setFirstname(event.target.value?.trim() || "")}/>
      <TextField size="small" style={{flexGrow: 1}} placeholder="Last name" onKeyDown={onKeyDown}
                 onChange={event => setLastname(event.target.value?.trim() || "")}/>
    </Box>
    <TextField size="small" placeholder="Email" type="email" required onKeyDown={onKeyDown}
               onChange={event => setEmail(event.target.value?.trim() || "")}/>
    <TextField
      size="small"
      placeholder="Password"
      type={showPassword ? "type" : "password"}
      required
      onKeyDown={onKeyDown}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(showPassword => !showPassword)}
              onMouseDown={(event) => event.preventDefault()}
              edge="end"
            >
              {showPassword ? <VisibilityOff/> : <Visibility/>}
            </IconButton>
          </InputAdornment>
        )
      }}
      onChange={event => setPassword(event.target.value?.trim() || "")}
    />
    {error.length > 0 ? <Typography variant="caption" style={{color: colorRed,}}>{error}</Typography> : null}
    <Box style={{display: "flex", gap: 12, alignItems: "center"}}>
      <Checkbox checked={agree} onChange={(event, checked) => setAgree(checked)}/>
      <Typography style={{marginLeft: -12}}>I have read and agree to the
        <a href="#"
           onClick={() => BaseApp.CONTEXT.showDialog(null, () =>
             <HtmlFragment
               url="/privacy.html"/>)}>
          Privacy Policy
        </a> and
        <a href="#"
           onClick={() => BaseApp.CONTEXT.showDialog(null, () => <HtmlFragment url="/terms.html"/>)}>
          Terms & Conditions
        </a>.</Typography>
    </Box>
    <Button variant="contained" disabled={!signupEnabled}
            onClick={() => doSignup()}>Sign Up
    </Button>
    <hr/>
    <Typography style={{marginTop: PD_MD}}>Already have an account? <a href="#"
                                                                       onClick={() => props.setView(View.LOGIN)}>Log
      in</a></Typography>
  </Box>;
}

function renderContinueWithGoogleButton(auth: Auth, onClick: () => void) {
  return <button className="gsi-material-button" onClick={() => onClick()}>
    <div className="gsi-material-button-state"></div>
    <div className="gsi-material-button-content-wrapper">
      <div className="gsi-material-button-icon">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" style={{display: "block"}}>
          <path fill="#EA4335"
                d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
          <path fill="#4285F4"
                d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
          <path fill="#FBBC05"
                d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
          <path fill="#34A853"
                d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
          <path fill="none" d="M0 0h48v48H0z"></path>
        </svg>
      </div>
      <span className="gsi-material-button-contents">Continue with Google</span>
      <span style={{display: "none"}}>Continue with Google</span>
    </div>
  </button>;
}

function LoginFragment(props: {
  auth: Auth,
  setView?: (view: View) => void,
  providers: LoginProviders[],
  signupDisabled?: boolean
}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const providers = props.providers || [LoginProviders.EMAIL];

  function doLogin() {
    if (!validateEmail(email)) {
      setError("Invalid email: " + email + ". Please enter a valid email.");
      return;
    }
    if (password.length < 8) {
      setError("Please enter a password with at least 8 characters.");
      return;
    }
    signInWithEmailAndPassword(props.auth, email, password)
      .then((value: UserCredential) => {
        setLoginCredentials({
          email: email,
          password: password,
        });
        window.location.href = "/";
      }).catch(reason => {
      setError("Error logging in. Please check your email and password, and try again.");
    });
  }

  function doLoginWithGoogle() {
    signInWithPopup(props.auth, new GoogleAuthProvider())
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        const info = getAdditionalUserInfo(result);
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      }).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      setError("Error logging in. Please check your browser settings, and try again.");
    });
  }

  const onKeyDown = event => {
    if (event.keyCode === 13) {
      event.preventDefault();
      doLogin();
    }
  };

  const loginEnabled = email.length > 0 && password.length > 0;
  return <Box style={{display: "flex", flexDirection: "column", gap: PD_MD}}>
    {providers.includes(LoginProviders.GOOGLE) ? <>
        {renderContinueWithGoogleButton(props.auth, () => doLoginWithGoogle())}
        {providers.length > 1 ? <Typography style={{textAlign: "center"}}>OR</Typography> : null}
      </>
      : null}
    {providers.includes(LoginProviders.EMAIL)
      ? <>
        <TextField size="small" autoFocus placeholder="Email" type="email" required onKeyDown={onKeyDown}
                   onChange={event => setEmail(event.target.value?.trim() || "")}/>
        <TextField
          size="small"
          placeholder="Password"
          type={showPassword ? "type" : "password"}
          required
          onKeyDown={onKeyDown}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(showPassword => !showPassword)}
                  onMouseDown={(event) => event.preventDefault()}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff/> : <Visibility/>}
                </IconButton>
              </InputAdornment>
            )
          }}
          onChange={event => setPassword(event.target.value?.trim() || "")}
        />
        {error.length > 0 ? <Typography variant="caption" style={{color: colorRed,}}>{error}</Typography> : null}
        <Button
          variant="contained"
          disabled={!loginEnabled}
          onClick={() => doLogin()}>Log in</Button>
        <Typography><a href="/forgot">Forgot password?</a></Typography>
      </>
      : null}
    <hr/>
    <Typography>By using this app, you agree to the <a href="#" onClick={() => {
      BaseApp.CONTEXT.showDialog(null, () => <HtmlFragment url="/privacy.html"/>);
    }}>Privacy Policy </a>
      and <a href="#" onClick={() => BaseApp.CONTEXT.showDialog(null, () => <HtmlFragment url="/terms.html"/>)}>
        Terms & Conditions</a>.</Typography>
    {providers.includes(LoginProviders.EMAIL) && Boolean(!props.signupDisabled)
      ? <>
        <Typography style={{marginTop: PD_MD}}>
          Don't have an account? <a href="#" onClick={() => props.setView(View.SIGNUP)}>Sign up</a></Typography>
      </>
      : null}
  </Box>;
}

function VerifyCustomerFragment(props: {
  auth: Auth,
  setView?: (view: View) => void,
  setupRequired?: (provisioningId: string) => Promise<boolean>,
}) {
  const [provisioningCode, setProvisioningCode] = useState("");
  const [error, setError] = useState("");

  async function setupIfNeeded(provisioningId: string): Promise<boolean> {
    if (!props.setupRequired || !(await props.setupRequired(provisioningId))) {
      return true;
    }
    // TODO: Do This
    // if (await props.onSetup?.(provisioningId)) {
    //   await set(dbRef("/provisionings/" + provisioningId + "/setupComplete"), true);
    //   return true;
    // }
    return false;
  }

  async function doVerifyCustomer() {
    const provisioningId = md5(provisioningCode);
    const val = await get(dbRef("/provisionings/" + provisioningId));
    if (val.exists()) {
      setProvisioningId(provisioningId);
      setupIfNeeded(provisioningId).then(provisioningOk => {
        if (provisioningOk) {
          window.location.href = "/";
        } else {
          props.setView(View.SETUP);
        }
      });
    } else {
      setError("Provisioning code is not valid.");
    }
  }

  const verifyCustomerEnabled = provisioningCode.length >= PROVISIONING_CODE_MIN_LENGTH;
  const onKeyDown = event => {
    if (verifyCustomerEnabled && event.keyCode === 13) {
      event.preventDefault();
      doVerifyCustomer();
    }
  };

  return <Box style={{display: "flex", flexDirection: "column", gap: PD_MD}}>
    <Typography>Enter your provisioning code to continue to the app.</Typography>
    <TextField size="small" autoFocus placeholder="Provisioning code" type="text" required onKeyDown={onKeyDown}
               inputProps={{maxLength: PROVISIONING_CODE_MAX_LENGTH}}
               value={provisioningCode}
               onChange={event => setProvisioningCode(event.target.value?.trim().toUpperCase() || "")}/>
    {error.length > 0 ? <Typography variant="caption" style={{color: colorRed,}}>{error}</Typography> : null}
    <Button
      variant="contained"
      disabled={!verifyCustomerEnabled}
      onClick={() => doVerifyCustomer()}>Continue</Button>
  </Box>;
}

function SetupErrorFragment() {
  return <Box style={{display: "flex", flexDirection: "column", gap: PD_MD}}>
    <Typography>There was an error with this setup. Please contact support.</Typography>
  </Box>
}

export type FirebaseConfig = {
  noOverride?: boolean, // When this is true, if this is a plugin app, host doesn't override default firebase config.
  options: FirebaseOptions,
}

export type LoginProvisioningConfig = {
  provisioningId?: string,
  enabled?: boolean,
  signupDisabled?: boolean,
  onChangeProvisioning?: () => void,
  setupRequired?: (provisioningId: string) => Promise<boolean>,
  renderSetup?: (provisioningId: string, onSetupComplete: () => void) => ReactElement, // True means setup handled. If setupRequired "true" and onSetup not set, shows an error.
}

export type LoginRolesConfig = {
  enabled?: boolean,
  onSwitchLoginRole: () => void,
}

export type LoginDomainsConfig = {
  enabled?: boolean,
  domainName?: string,
}

export enum LoginProviders {
  EMAIL = "email",
  GOOGLE = "google",
  // APPLE = "apple",
}

export type LoginUiProps = {
  topDecorator?: ReactElement,
  bottomDecorator?: ReactElement,
}
export type LoginConfig = {
  noLogin?: boolean,
  provisioning?: LoginProvisioningConfig,
  roles?: LoginRolesConfig,
  domains?: LoginDomainsConfig,
  providers?: LoginProviders[],
  uiProps?: LoginUiProps,
}

export type LoginProps = {
  config?: LoginConfig,
}

export function Login(props: LoginProps) {
  const auth = getAuth();
  const provisioningId = getProvisioningId();
  const [view, setView] = useState((!provisioningId || provisioningId === SystemProvisioningIds.MAIN) && props.config?.provisioning?.enabled ? View.VERIFY_CUSTOMER : View.LOGIN);

  function getFragment(providers: LoginProviders[], signupDisabled: boolean, view: View, setView: (view: View) => void, auth: Auth) {
    switch (view) {
      case View.VERIFY_CUSTOMER:
        return <VerifyCustomerFragment
          auth={auth} setView={setView}
          setupRequired={props.config?.provisioning?.setupRequired}
        />
      case View.SETUP:
        const provisioningId = getProvisioningId();
        const setupRendered = props.config?.provisioning?.renderSetup?.(provisioningId, () => {
          new ProvisioningContext(provisioningId).dbRef_setVal("/provisionings/" + provisioningId + "/setupComplete", true)
            .then(() => setView(View.LOGIN));
        });
        return setupRendered || <SetupErrorFragment/>;
      case View.LOGIN:
        return <LoginFragment auth={auth} setView={setView} providers={providers} signupDisabled={signupDisabled}/>
      case View.SIGNUP:
        return <SignupFragment auth={auth} setView={setView}/>
    }
  }

  return <Box style={{
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: lightGray,
  }}>
    {props.config?.roles?.enabled
      ? <ButtonBase
        style={{width: 24, height: 24, position: "absolute", top: 24, right: 24}}
        onClick={() => {
          props.config.roles.onSwitchLoginRole();
          window.location.href = "/";
        }}>
        <RefreshOutlined/>
      </ButtonBase>
      : (view !== View.VERIFY_CUSTOMER && props.config?.provisioning?.enabled
          ? <Button
            style={{position: "absolute", top: 24, right: 24}}
            onClick={() => {
              props.config.provisioning.onChangeProvisioning?.();
              window.location.href = "/";
            }}>
            Change Provisioning Code
          </Button>
          : null
      )}
    <StyledBoxColumn>
      {props.config?.uiProps?.topDecorator}
      <Box style={{
        background: "white",
        border: DIVIDER,
        borderRadius: BORDER_RADIUS,
        padding: 24,
        display: "flex",
        flexDirection: "column",
        width: 400,
        gap: 24,
      }}>
        {renderStamp()}
        {getFragment(props.config?.providers, props.config?.provisioning?.signupDisabled, view, setView, auth)}
      </Box>
      {props.config?.uiProps?.bottomDecorator}
    </StyledBoxColumn>
  </Box>;
}
