import {PageFragment, PageFragmentProps, PageFragmentState} from "../../shared/PageFragment";
import React, {Component, ReactElement} from "react";
import {
  BaseFormQuestionItemData,
  Form,
  FORM_QUESTION_FIELD_TYPES,
  FormAnswerIds,
  FormQuestionItem,
  FormQuestionItemChoiceData,
  FormQuestionItems,
  FormQuestionItemTextData,
  FormQuestionItemType,
  Forms,
  FormStatus
} from "./types";
import {StyledBoxColumn, StyledBoxRow, StyledContainer, StyledSpan} from "../../shared/StyledComponents";
import {DIVIDER, PD_MD, PD_SM, PD_XLG} from "../../shared/dimens";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonBase,
  Card,
  Grid,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  Switch,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import {
  AddOutlined,
  ArchiveOutlined,
  ArrowDownwardOutlined,
  ArrowUpwardOutlined,
  ContentCopyOutlined,
  DeleteOutlined,
  ExpandMoreOutlined,
  FavoriteBorderOutlined,
  FavoriteOutlined,
  LeaderboardOutlined,
  PreviewOutlined
} from "@mui/icons-material";
import {Action, ListItemChange, OnListItemsListener} from "../../shared/types";
import Checkbox from "@mui/material/Checkbox";
import {FormGenContainer, FormGenContainerMode} from "../../shared/FormGenContainer";
import {colorRed} from "../../shared/colors";
import {DateUtil} from "../../shared/date_util";
import {App} from "../App";
import {Settings} from "../settings/settings";
import {
  PageWithSidebarContainer,
  PageWithSidebarContainerRenderer,
  SidebarLocation
} from "../../shared/PageWithSidebarContainer";
import {Sidebar} from "../../shared/Sidebar";
import {BaseApp, DIALOG_FLAG_SHOW_DIALOG_FULLSCREEN} from "../../shared/BaseApp";
import {THEME_COLOR_PRIMARY} from "../../fyneapps-shared/consts";
import {getMemberAuth} from "../../shared/auth";
import {JSON_OBJECT} from "../../shared/json/helpers";
import {ViewFormFragment, ViewMode} from "./ViewFormFragment";
import {FormStatusView} from "../FormStatusView";
import {getPublishActions} from "./EditFormHelper";

function FormContentAddQuestionView(props: {
  focused?: boolean,
  onAddQuestionItemType: (type: FormQuestionItemType) => void
}) {
  if (!props.focused) {
    return null;
  }
  return <Accordion disableGutters style={{padding: PD_SM}}>
    <AccordionSummary
      style={{paddingLeft: PD_SM, paddingRight: PD_SM}}
      sx={{minHeight: "0px", ".MuiAccordionSummary-content": {margin: 0},}}
      expandIcon={<ExpandMoreOutlined/>}>
      <StyledBoxRow style={{padding: PD_SM}}>
        <AddOutlined/>
        <Typography style={{textTransform: "uppercase"}} variant="body2"
                    color={BaseApp.CONTEXT.getAppConfig().theme.palette.primary.main}>
          <b>Add question</b>
        </Typography>
      </StyledBoxRow>
    </AccordionSummary>
    <AccordionDetails style={{padding: 0}}>
      <Grid container spacing={2}>
        {FORM_QUESTION_FIELD_TYPES.map(type => <Grid item xs={4}>
          <Button
            variant="outlined"
            style={{padding: PD_MD, width: "100%"}}
            startIcon={<type.iconType/>}
            onClick={() => props.onAddQuestionItemType(type.type)}>
            {type.displayName}
          </Button>
        </Grid>)}
      </Grid>
    </AccordionDetails>
  </Accordion>;
}

type BaseFocusableViewProps = {
  form: Form,
  onAddQuestionItemType: (type: FormQuestionItemType) => void,
}

type BaseFocusableViewState = {
  focused?: boolean,
}

abstract class BaseFocusableView<P extends BaseFocusableViewProps = BaseFocusableViewProps, S extends BaseFocusableViewState = BaseFocusableViewState> extends Component<P, S> {

  private static readonly instances: BaseFocusableView[] = [];
  private static focusedInstance: BaseFocusableView;

  constructor(props: P, context: any) {
    super(props, context);
    this.state = {} as S;
  }

  componentDidMount() {
    BaseFocusableView.instances.push(this);
  }

  componentWillUnmount() {
    const index = BaseFocusableView.instances.findIndex(instance => instance === this);
    if (index < 0) {
      return;
    }
    BaseFocusableView.instances.splice(index, 1);
  }

  onWillFocus(): void {
  }

  onWillBlur(): void {
  }

  static setFocusedView(view: BaseFocusableView) {
    if (view === this.focusedInstance) {
      return;
    }
    this.focusedInstance?.onWillBlur();
    view?.onWillFocus();
    BaseFocusableView.instances.forEach(instance => {
      instance.setState({
        focused: instance === view,
      });
    });
  }

  render() {
    return <Card>
      <StyledBoxColumn style={{
        gap: 0,
        padding: PD_SM,
        position: "relative",
        border: this.state.focused ? (THEME_COLOR_PRIMARY + " 2px solid") : null
      }}>
        {this.renderContent(Boolean(this.state.focused))}
        <FormContentAddQuestionView
          focused={this.state.focused}
          onAddQuestionItemType={this.props.onAddQuestionItemType}/>
        {!this.state.focused
          ? <Typography variant="caption" style={{opacity: 0.5}}>Tap to edit block or add questions</Typography>
          : null}
        {!this.state.focused
          ? <ButtonBase style={{position: "absolute", left: 0, top: 0, right: 0, bottom: 0}}
                        onClick={() => BaseFocusableView.setFocusedView(this)}/>
          : null}
      </StyledBoxColumn>
    </Card>;
  }

  protected abstract renderContent(focused: boolean);
}

type HeaderViewProps = BaseFocusableViewProps & {
  updateForm: (form: Form) => void,
}

class HeaderView extends BaseFocusableView<HeaderViewProps> {

  protected renderContent(focused: boolean) {
    const form = this.props.form;
    return <>
      <StyledBoxRow style={{alignItems: "center", padding: PD_SM}}>
        <FormStatusView form={form}/>
        <Typography variant="body2">Created {DateUtil.formatDateTime(form.created)}</Typography>
        <StyledSpan/>
        <Checkbox
          icon={<FavoriteBorderOutlined/>}
          checkedIcon={<FavoriteOutlined/>}
          checked={this.props.form.favorite}
          onChange={(event, checked) => {
            this.props.form.favorite = checked;
            Forms.getInstance().addListItem(this.props.form);
            this.forceUpdate();
          }}/>
      </StyledBoxRow>
      <StyledBoxColumn style={{padding: PD_XLG, gap: PD_XLG, marginTop: -24}}>
        <FormGenContainer
          mode={focused ? FormGenContainerMode.EDIT : FormGenContainerMode.READ}
          autoSave
          onContainerSave={() => this.props.updateForm(this.props.form)}
          content={this.props.form}/>
      </StyledBoxColumn>
    </>;
  }
}

type BaseQuestionItemViewProps = BaseFocusableViewProps & {
  item: FormQuestionItem,
}

abstract class BaseQuestionItemView<T extends BaseFormQuestionItemData, P extends BaseQuestionItemViewProps = BaseQuestionItemViewProps> extends BaseFocusableView<P> {

  private readonly formQuestionItems = new FormQuestionItems(this.props.form.id);

  protected renderContent(focused: boolean) {
    const data = this.readData();
    return <>
      <StyledBoxRow style={{alignItems: "center", borderBottom: DIVIDER, padding: PD_SM, marginTop: -8}}>
        <Typography>Required</Typography>
        <Switch
          defaultChecked={this.props.item.required}
          onChange={(event, checked) => {
            this.props.item.required = checked;
            this.formQuestionItems.addListItem(this.props.item);
          }}/>
        <StyledSpan/>
        <Tooltip title={"Duplicate"}>
          <IconButton>
            <ContentCopyOutlined/>
          </IconButton>
        </Tooltip>
        <Tooltip title={"Delete"}>
          <IconButton>
            <DeleteOutlined/>
          </IconButton>
        </Tooltip>
        <Tooltip title={"Move up"}>
          <IconButton>
            <ArrowUpwardOutlined/>
          </IconButton>
        </Tooltip>
        <Tooltip title={"Move down"}>
          <IconButton>
            <ArrowDownwardOutlined/>
          </IconButton>
        </Tooltip>
      </StyledBoxRow>
      <FormGenContainer
        mode={focused ? FormGenContainerMode.EDIT : FormGenContainerMode.READ}
        autoSave
        onContainerSave={() => {
          this.writeData(data);
          this.formQuestionItems.addListItem(this.props.item);
        }}
        content={data}/>
      <StyledBoxColumn style={{padding: PD_MD, marginTop: -16}}>
        {this.renderDetails(focused)}
      </StyledBoxColumn>
    </>;
  }

  protected abstract readData(): T;

  protected abstract writeData(data: T): void;

  protected abstract renderDetails(focused: boolean): ReactElement;
}

class QuestionItemChoiceView extends BaseQuestionItemView<FormQuestionItemChoiceData> {

  protected readData(): FormQuestionItemChoiceData {
    return JSON_OBJECT.deserializeObject(this.props.item.data, FormQuestionItemChoiceData);
  }

  protected writeData(data: FormQuestionItemChoiceData): void {
    this.props.item.data = JSON_OBJECT.serializeObject(data);
  }

  protected renderDetails(focused: boolean) {
    return <>
      <StyledBoxRow style={{alignItems: "center"}}>
        <Radio disabled/>
        <TextField size="small" placeholder={"Option 1"}/>
      </StyledBoxRow>
      <StyledBoxRow style={{alignItems: "center"}}>
        <Radio disabled/>
        <TextField size="small" placeholder={"Option 2"}/>
      </StyledBoxRow>
    </>;
  }
}

class QuestionItemTextView extends BaseQuestionItemView<FormQuestionItemTextData> {

  protected readData(): FormQuestionItemTextData {
    return JSON_OBJECT.deserializeObject(this.props.item.data, FormQuestionItemTextData);
  }

  protected writeData(data: FormQuestionItemTextData): void {
    this.props.item.data = JSON_OBJECT.serializeObject(data);
  }

  protected renderDetails(focused: boolean) {
    return <>
      <TextField size="small" placeholder={"Your answer..."} disabled/>
    </>;
  }
}

type FormContentFragmentProps = PageFragmentProps & {
  form: Form,
  updateForm: (form: Form) => void,
}

class FormContentFragment extends PageFragment<FormContentFragmentProps> implements OnListItemsListener<FormQuestionItem> {

  private readonly formQuestionItems = new FormQuestionItems(this.props.form.id);

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    await this.formQuestionItems.loadListItems();
  }

  componentDidMount() {
    super.componentDidMount();
    this.formQuestionItems.registerObserver(this);
  }

  componentWillUnmount() {
    this.formQuestionItems.unregisterObserver(this);
    super.componentWillUnmount();
  }

  onItemChanged(item: FormQuestionItem, change: ListItemChange) {
    if (change === ListItemChange.ADDED || change === ListItemChange.REMOVED) {
      this.reload();
    }
  }

  renderContent(): ReactElement {
    return <>
      <StyledContainer
        style={{position: "relative", gap: PD_XLG, opacity: this.props.form.status === FormStatus.ARCHIVED ? 0.75 : 1}}>
        <ButtonBase style={{position: "absolute", cursor: "default", left: 0, right: 0, top: 0, bottom: 0}}
                    onClick={() => BaseFocusableView.setFocusedView(null)}/>
        <HeaderView
          form={this.props.form}
          updateForm={this.props.updateForm}
          onAddQuestionItemType={(type) => this.onAddQuestionItemType(type)}
        />
        {this.formQuestionItems.getListItems().map(item => this.renderItem(item))}
        {this.props.form.status === FormStatus.ARCHIVED
          ? <ButtonBase style={{zIndex: 1000, position: "absolute", cursor: "default", left: 0, right: 0, top: 0, bottom: 0}}
                        onClick={() => {
                        }}/>
          : null}
      </StyledContainer>
    </>;
  }

  private renderItem(item: FormQuestionItem): ReactElement {
    switch (item.type) {
      case FormQuestionItemType.CHOICE:
        return <QuestionItemChoiceView
          form={this.props.form}
          item={item}
          onAddQuestionItemType={type => this.onAddQuestionItemType(type)}
        />;
      case FormQuestionItemType.TEXT:
        return <QuestionItemTextView
          form={this.props.form}
          item={item}
          onAddQuestionItemType={type => this.onAddQuestionItemType(type)}
        />;
    }
    return null;
  }

  private onAddQuestionItemType(type: FormQuestionItemType): void {
    this.formQuestionItems.addListItem(FormQuestionItem.createNewItemType(getMemberAuth().getMemberId(), Date.now(), this.props.form.id, type));
  }

  private renderFolderAction(action: Action): ReactElement {
    return <ListItemButton
      onClick={(event) => action.onClick(event)}>
      <ListItemIcon
        style={{color: action.destructive ? colorRed : null}}>
        <action.iconType/>
      </ListItemIcon>
      <ListItemText
        style={{color: action.destructive ? colorRed : null}}>
        {action.text}
      </ListItemText>
    </ListItemButton>;
  }
}

export type FormFragmentProps = PageFragmentProps & {
  formId: string,
}

type FormFragmentState = PageFragmentState & {
  form: Form,
  answerIds?: string[],
}

export class FormFragment extends PageFragment<FormFragmentProps, FormFragmentState> implements PageWithSidebarContainerRenderer, OnListItemsListener<Form> {

  private readonly forms = Forms.getInstance();

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    this.setState({
      form: await this.forms.getOrLoadItem(this.props.formId),
      answerIds: await new FormAnswerIds(this.props.formId).getOrLoadListItems(),
    });
  }

  componentDidMount() {
    super.componentDidMount();
    this.forms.registerObserver(this);
  }

  componentWillUnmount() {
    this.forms.unregisterObserver(this);
    super.componentWillUnmount();
  }

  onItemChanged(item: Form) {
    if (item.id === this.state.form?.id) {
      this.setState({
        form: item,
      });
    }
  }

  protected styleFlags(): number {
    return PageFragment.STYLE_TOOLBAR_TYPE_FLAG | PageFragment.STYLE_BACK_BUTTON_FLAG;
  }

  protected getToolbar(toolbarModeId: string | null): React.ReactElement {
    const form = this.state.form;
    if (!form) {
      return null;
    }
    const actions: Action[] = [];
    actions.push(new Action("Preview", () => {
      App.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_DIALOG_FULLSCREEN}, (dialogProps) =>
        <ViewFormFragment dialogProps={dialogProps} path={this.props.path} viewMode={ViewMode.PREVIEW} formId={form.id}/>);
    }, PreviewOutlined));
    actions.push(...getPublishActions(form));
    actions.push(new Action("Answers", () => {
      App.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_DIALOG_FULLSCREEN}, (dialogProps) =>
        <ViewFormFragment dialogProps={dialogProps} path={this.props.path} viewMode={ViewMode.ANSWERS} formId={form.id}/>);
    }, LeaderboardOutlined).setVariant("contained")
      .setBadgeText("" + (this.state.answerIds?.length ? this.state.answerIds?.length : ""))
      .makeSecondary());

    return <StyledBoxRow style={{flexGrow: 1}}>
      <StyledSpan/>
      {this.renderToolbarButtonsInToolbarContainer(actions)}
    </StyledBoxRow>
  }

  componentDidUpdate(prevProps: Readonly<FormFragmentProps>, prevState: Readonly<FormFragmentState>, snapshot?: any) {
    super.componentDidUpdate(prevProps, prevState, snapshot);
    if (prevProps.formId !== this.props.formId) {
      this.reload();
    }
  }

  private async updateForm(form: Form) {
    this.setState({
      form: form,
    });
    await this.forms.addListItem(form);
  }

  renderContent(): React.ReactElement {
    const form = this.state.form;
    if (!form) {
      return this.renderNotFound();
    }
    return <PageWithSidebarContainer sidebarLocation={SidebarLocation.RIGHT} renderer={this}/>;
  }

  renderPageWithSidebarContainerContent(): React.ReactElement {
    return <FormContentFragment
      form={this.state.form}
      updateForm={form => this.updateForm(form)}
    />;
  }

  renderPageWithSidebarContainerSidebar(): React.ReactElement {
    const forms = this.forms;
    const form = this.state.form;
    const onClose = this.onBackButtonClicked.bind(this);
    return <StyledBoxColumn style={{padding: PD_SM}}>
      {form.status !== FormStatus.ARCHIVED
        ? Sidebar.renderSidebarCustomCondensed(
          () => <FormGenContainer
            autoSave onContainerSave={container => this.updateForm(form)}
            content={this.state.form.properties}/>,
          "Details")
        : null}
      {form.status !== FormStatus.ARCHIVED
        ? <Button
          startIcon={<ArchiveOutlined/>}
          onClick={() => {
            function doArchive() {
              form.status = FormStatus.ARCHIVED;
              forms.addListItem(form);
              onClose();
            }

            if (Settings.getInstance().general.confirmBeforeDeleting) {
              App.CONTEXT.showTextDialog("Archive", "Are you sure you want to archive \"" + form.title + "\". You cannot undo this action.", new Action("Confirm", () => {
                doArchive();
              }));
            } else {
              doArchive();
            }
          }}
        >Archive</Button>
        : null}
      {form.status === FormStatus.ARCHIVED
        ? <Typography style={{padding: PD_MD}}>This form has been archived, and cannot be used any more.</Typography>
        : null}
      <Button
        startIcon={<DeleteOutlined/>}
        style={{margin: PD_SM, color: colorRed}}
        onClick={() => {
          function doDelete() {
            forms.deleteListItemById(form.id);
            onClose();
          }

          if (Settings.getInstance().general.confirmBeforeDeleting) {
            App.CONTEXT.showTextDialog("Delete", "Are you sure you want to delete \"" + form.title + "\". You cannot undo this action.", new Action("Confirm", () => {
              doDelete();
            }));
          } else {
            doDelete();
          }
        }}
      >Delete</Button>
    </StyledBoxColumn>;
  }
}