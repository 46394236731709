import {DW_LG} from "shared/dimens";
import {App} from "app/App";
import React from "react";
import {StyledBoxColumn} from "../../shared/StyledComponents";
import {AccountBoxOutlined, PauseCircleOutlineOutlined, PlayCircleOutlineOutlined} from "@mui/icons-material";
import {Action} from "../../shared/types";
import {PathProps} from "../../index";
import {Form, Forms, FormStatus} from "./types";
import {DIALOG_FLAG_DISABLE_BACKDROP_CLICK, DIALOG_FLAG_SHOW_CLOSE} from "../../shared/BaseApp";
import {DialogFragment, PageFragment, PageFragmentProps} from "../../shared/PageFragment";
import {FormGenContainer, FormGenContainerMode} from "../../shared/FormGenContainer";
import {FormPublishViaFragment} from "./FormPublishViaFragment";

export function onManageForm(path: PathProps, form: Form, folderId?: string) {
  path.navigate((form?.folderId || folderId ? "/folders/" + (form?.folderId || folderId) : "") + "/forms" + (form ? "/" + form.id : ""));
}

export function getPublishActions(form: Form) {
  const actions: Action[] = [];
  if (form.status === FormStatus.DRAFT || form.status === FormStatus.PAUSED) {
    actions.push(new Action("Publish", () => {
      showPublishDialog(form);
    }, PlayCircleOutlineOutlined).setVariant("contained"));
  }
  if (form.status === FormStatus.PUBLISHED) {
    actions.push(new Action("Pause", () => {
      showPauseDialog(form);
    }, PauseCircleOutlineOutlined).setVariant("contained"));
  }
  return actions;
}

function showPublishDialog(form: Form) {
  App.CONTEXT.showCustomDialog("Publish",
    <StyledBoxColumn>
      <FormPublishViaFragment form={form}/>
    </StyledBoxColumn>,
    new Action("Publish", () => {
      form.status = FormStatus.PUBLISHED;
      Forms.getInstance().addListItem(form);
      App.CONTEXT.hideDialog();
    }), null, true);
}

function showPauseDialog(form: Form) {
  App.CONTEXT.showCustomDialog("Pause",
    <StyledBoxColumn>
      <FormPublishViaFragment form={form}/>
    </StyledBoxColumn>,
    new Action("Pause", () => {
      form.status = FormStatus.PAUSED;
      Forms.getInstance().addListItem(form);
      App.CONTEXT.hideDialog();
    }), null, true);
}

export class EditFormHelper {

  static addForm() {
    App.CONTEXT.showDialog(
      {flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_DISABLE_BACKDROP_CLICK},
      () => <EditFormFragment onFormSaved={form => App.CONTEXT.hideDialog()}/>);
  }

  static editForm(path: PathProps, form: Form) {
    App.CONTEXT.showDialog(
      {flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_DISABLE_BACKDROP_CLICK},
      () => <EditFormFragment path={path} edit={form} onFormSaved={form => App.CONTEXT.hideDialog()}/>);
  }
}

type EditFormFragmentProps = PageFragmentProps & {
  edit?: Form,
  existing?: boolean,
  onFormSaved: (form: Form) => void,
}

class EditFormFragment extends DialogFragment<EditFormFragmentProps> {

  private readonly forms = Forms.getInstance();
  private readonly form = this.props.edit ? this.props.edit : Form.createNew();

  protected onCreateState() {
    return {
      ...super.onCreateState(),
      title: this.props.edit ? "Edit" : "Add",
    };
  }

  protected styleFlags(): number {
    return PageFragment.STYLE_ACTION_PANEL_TYPE_BUTTON_FLAG | PageFragment.STYLE_TOOLBAR_TYPE_FLAG;
  }

  protected getToolbar(toolbarModeId: string | null): React.ReactElement {
    if (this.props.edit) {
      return this.renderToolbarButtonsInToolbarContainer([
        new Action("Manage Form", () => {
          this.hideDialog();
          onManageForm(this.props.path, this.form);
        }, AccountBoxOutlined),
      ]);
    }
    return null;
  }

  protected getActionButtonText(): string {
    return this.props.edit ? "Save" : "Add";
  }

  onActionButtonClicked() {
    this.addForm().then(() => this.props.onFormSaved(this.form));
  }

  private async addForm() {
    await this.forms.addListItem(this.form);
  }

  renderContent(): React.ReactElement | null {
    return <StyledBoxColumn style={{display: "flex", width: DW_LG}}>
      <FormGenContainer
        mode={this.props.edit ? FormGenContainerMode.EDIT : FormGenContainerMode.CREATE}
        content={this.form}/>
    </StyledBoxColumn>;
  }
}
