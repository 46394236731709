import {ListTableDataSourceFragment} from "../shared/ListTableDataSourceFragment";
import {AbstractListItemsLoader, Action, BaseListItem} from "../shared/types";
import {TableDataRow} from "../shared/TableDataContainer";
import {TableDataSourceFragmentProps, TableDataSourceFragmentState} from "../shared/TableDataSourceFragment";
import {TuneOutlined} from "@mui/icons-material";
import React, {Component, ReactElement} from "react";
import {PAGE_FRAGMENT_HALF_WIDTH, PD_MD} from "../shared/dimens";
import {FormGenContainer} from "../shared/FormGenContainer";
import {StyledBoxColumn} from "../shared/StyledComponents";
import {App} from "./App";
import {DisplayOptions, DisplayOptionsListener} from "./types";
import {AppPrefs} from "./app_prefs";

type DisplayPopoverContentProps = {
  displayOptions: DisplayOptions,
}

type DisplayPopoverContentState = {}

class DisplayPopoverContent extends Component<DisplayPopoverContentProps, DisplayPopoverContentState> {

  constructor(props: DisplayPopoverContentProps, context: any) {
    super(props, context);
    this.state = {};
  }

  render() {
    return <StyledBoxColumn style={{
      display: "flex",
      flexDirection: "column",
      padding: PD_MD,
      width: PAGE_FRAGMENT_HALF_WIDTH,
    }}>
      <FormGenContainer content={this.props.displayOptions}/>
    </StyledBoxColumn>;
  }
}

export type BaseFormListTableDataSourceFragmentState = TableDataSourceFragmentState & {
  displayPopoverAnchorEl?: any,
  displayOptions: DisplayOptions,
}

export class BaseFormListTableDataSourceFragment<T extends BaseListItem, D extends TableDataRow, L extends AbstractListItemsLoader<T>, P extends TableDataSourceFragmentProps = TableDataSourceFragmentProps, S extends BaseFormListTableDataSourceFragmentState = BaseFormListTableDataSourceFragmentState> extends ListTableDataSourceFragment<T, D, L, P, S> implements DisplayOptionsListener {

  protected onCreateState(): S {
    return {
      ...super.onCreateState(),
      displayOptions: (App.CONTEXT.getAppPrefs<AppPrefs>().getFormsDisplayOptions() || new DisplayOptions()).setListener(this),
    };
  }

  onDisplayOptionsChanged(options: DisplayOptions) {
    this.setState({
      displayOptions: options.clone(),
    });
    App.CONTEXT.getAppPrefs<AppPrefs>().setFormsDisplayOptions(options);
  }

  createToolbarActions(): Action[] {
    const actions = super.createToolbarActions();
    actions.push(new Action("Display", (event) => {
      this.setState({displayPopoverAnchorEl: event.target});
    }, TuneOutlined));
    return actions;
  }

  createToolbarPopovers(): ReactElement[] {
    const popovers = super.createToolbarPopovers();
    popovers.push(this.renderPopover("Display",
      this.state.displayPopoverAnchorEl, <DisplayPopoverContent
        displayOptions={this.state.displayOptions}/>, () => {
        this.setState({displayPopoverAnchorEl: null})
      })
    );
    return popovers;
  }
}