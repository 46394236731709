import React, {ReactElement} from 'react';
import {PathComponent, PathProps} from "../index";
import {TabInfo} from "../shared/TabsContainer";
import {FolderOpenOutlined, SettingsOutlined, TableRowsOutlined} from "@mui/icons-material";
import {FormsFragment} from "./forms/FormsFragment";
import {FyneappsApp, FyneappsMain} from "../fyneapps-shared/FyneappsApp";
import {SettingsFragment} from "./settings/SettingsFragment";
import {BaseAppPrefs} from "../shared/BaseAppPrefs";
import {AppPrefs} from "./app_prefs";
import {FoldersFragment} from "./folders/FoldersFragment";
import {User} from "@firebase/auth";
import {Settings} from "./settings/settings";
import {LoginConfig} from "../shared/Login";
import {ViewFormFragment, ViewMode} from "./forms/ViewFormFragment";
import {Outlet} from "react-router-dom";

export enum TabType {
  FORMS = "forms",
  FOLDERS = "folders",
  SETTINGS = "settings",
}

class Main extends FyneappsMain {

  static appTabs(): TabInfo<string>[] {
    return [
      {
        type: TabType.FORMS,
        path: "forms",
        text: "Forms",
        iconType: TableRowsOutlined,
        nestedPaths: FormsFragment.nestedPaths(),
        render: (path: PathProps) => <FormsFragment path={path}/>,
      },
      {
        type: TabType.FOLDERS,
        path: "folders",
        text: "Folders",
        iconType: FolderOpenOutlined,
        nestedPaths: FoldersFragment.nestedPaths(),
        render: (path: PathProps) => <FoldersFragment path={path}/>,
      },
      {
        type: TabType.SETTINGS,
        path: "settings",
        text: "Settings",
        iconType: SettingsOutlined,
        nestedPaths: SettingsFragment.nestedPaths(),
        render: (path: PathProps) => <SettingsFragment path={path}/>,
      },
    ];
  }
}

export class App extends FyneappsApp {

  getAppPrefs<T extends BaseAppPrefs>(): T {
    //@ts-ignore
    return AppPrefs.getInstance() as T;
  }

  protected getLoginConfig(): LoginConfig | null | undefined {
    return {
      noLogin: true,
    }
  }

  protected async onAppInitWithLoggedInUser(user: User): Promise<void> {
    await super.onAppInitWithLoggedInUser(user);
    await Settings.loadInstance();
  }

  protected getAppName(): string {
    return "Forms";
  }

  protected getIconName(): string {
    return "@icon/dynamic_form";
  }

  static nestedPaths(): PathComponent[] {
    return [
      ...Main.nestedPaths(),
      {
        path: "v/:form_id",
        handle: {containerId: "viewer", path: "view/:form_id"},
        render: pathProps => <ViewFormFragment path={pathProps} viewMode={ViewMode.INPUT}/>,
      },
    ];
  }

  protected renderMain(): ReactElement {
    if (this.props.path?.matches.find(match => match.handle?.containerId === "viewer")) {
      return <Outlet/>;
    }
    if (!this.hasAuthCredentials()) {
      return this.renderLogin();
    }
    return <Main path={this.props.path}/>;
  }
}
