import {AbstractListItemsLoader, BaseListItem, OnListItemsListener} from "./types";
import {getMemberAuth} from "./auth";
import {PageFragment, PageFragmentProps, PageFragmentState} from "./PageFragment";

export abstract class BaseListFragment<T extends BaseListItem, L extends AbstractListItemsLoader<T>, P extends PageFragmentProps = PageFragmentProps, S extends PageFragmentState = PageFragmentState> extends PageFragment<P, S>
  implements OnListItemsListener<T> {

  protected readonly memberAuth = getMemberAuth();

  constructor(props: P, context: any, protected loader?: L) {
    super(props, context);
    this.loader = loader;
  }

  setLoader(loader: L) {
    this.loader?.unregisterObserver(this);
    this.loader = loader;
    this.loader?.registerObserver(this);
    this.reload(true);
  }

  componentDidMount() {
    super.componentDidMount();
    this.loader?.registerObserver(this);
  }

  componentWillUnmount() {
    this.loader?.unregisterObserver(this);
  }

  onItemChanged(item: T) {
    this.forceUpdate();
  }

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    await this.loader?.loadListItems();
  }
}