import {JsonObject} from "../shared/json/json-object";
import {FormGen} from "../shared/formgen";
import {JsonProperty} from "../shared/json/json-property";
import {$KTS, KeyTextStrings} from "../shared/types";

export enum Grouping {
  NONE = "none",
  DUE_DATE = "due_date",
  TAGGED_TEXT = "tagged_text",
}

const GROUPING_KTS = new KeyTextStrings([
  $KTS(Grouping.NONE, "None"),
  $KTS(Grouping.DUE_DATE, "Due date"),
  $KTS(Grouping.TAGGED_TEXT, "Tagged text"),
])

export enum Ordering {
  NONE = "none",
  DATE_CREATED = "date_created",
  IMPORTANCE = "importance",
}

const ORDERING_KTS = new KeyTextStrings([
  $KTS(Ordering.NONE, "None"),
  $KTS(Ordering.DATE_CREATED, "Date created"),
  $KTS(Ordering.IMPORTANCE, "Importance"),
])

export interface DisplayOptionsListener {
  onDisplayOptionsChanged(options: DisplayOptions);
}

@JsonObject()
export class DisplayOptions {

  @FormGen({
    name: "Grouping", type: "enum",
    enumValues: GROUPING_KTS.values,
    enumDefaultKey: Grouping.DUE_DATE,
    onValueSet: (target, value) => {
      const options = target as DisplayOptions;
      options.listener?.onDisplayOptionsChanged(options);
    }
  })
  @JsonProperty()
  grouping: string = Grouping.DUE_DATE;

  @FormGen({
    name: "Ordering", type: "enum",
    enumValues: ORDERING_KTS.values,
    enumDefaultKey: Ordering.NONE,
    onValueSet: (target, value) => {
      const options = target as DisplayOptions;
      options.listener?.onDisplayOptionsChanged(options);
    }
  })
  @JsonProperty()
  ordering: string;

  private listener: DisplayOptionsListener;

  constructor(listener?: DisplayOptionsListener) {
    this.listener = listener;
  }

  setListener(listener: DisplayOptionsListener): DisplayOptions {
    this.listener = listener;
    return this;
  }

  clone(): DisplayOptions {
    const clone = new DisplayOptions(this.listener);
    Object.assign(clone, this);
    return clone;
  }
}
