import {FyneappsAppPrefs} from "../fyneapps-shared/FyneappsAppPrefs";
import {DisplayOptions} from "./types";
import {JSON_OBJECT} from "../shared/json/helpers";

export class AppPrefs extends FyneappsAppPrefs {

  static readonly PREF_FORMS_DISPLAY_OPTIONS = "forms_display_options";

  private static instance: AppPrefs;

  static getInstance() {
    if (!this.instance) {
      this.instance = new AppPrefs();
    }
    return this.instance;
  }

  getFormsDisplayOptions(): DisplayOptions {
    const string = this.prefs.getString(AppPrefs.PREF_FORMS_DISPLAY_OPTIONS);
    if (!string) {
      return undefined;
    }
    return JSON_OBJECT.deserializeObject(JSON.parse(string), DisplayOptions);
  }

  setFormsDisplayOptions(displayOptions: DisplayOptions) {
    this.prefs.setString(AppPrefs.PREF_FORMS_DISPLAY_OPTIONS, JSON.stringify(JSON_OBJECT.serializeObject(displayOptions)));
    this.notify(AppPrefs.PREF_FORMS_DISPLAY_OPTIONS);
  }
}

