import {PageFragment, PageFragmentProps, PageFragmentState} from "../../shared/PageFragment";
import {PageWithSidebarContainer, PageWithSidebarContainerRenderer} from "../../shared/PageWithSidebarContainer";
import React from "react";
import {Sidebar, SidebarItem, SidebarItems, SidebarListener} from "../../shared/Sidebar";
import {Outlet} from "react-router-dom";
import {Action} from "../../shared/types";
import {AddOutlined, RecentActorsOutlined} from "@mui/icons-material";
import {PathComponent} from "../../index";
import {FormsFragment} from "../forms/FormsFragment";
import {Folder, Folders} from "./types";
import {EditFolderHelper} from "./EditFolderHelper";
import {StyledEmpty} from "../../shared/StyledComponents";
import {BaseListFragment} from "../../shared/BaseListFragment";

type FolderFragmentProps = PageFragmentProps & {
  folderId: string,
  formId?: string,
}

class FolderFragment extends PageFragment<FolderFragmentProps> {

  renderContent(): React.ReactElement {
    if (!this.props.folderId) {
      return <StyledEmpty emptyConfig={{
        title: "Select a folder",
        text: "Select a folder from the sidebar to see Forms.",
        iconType: RecentActorsOutlined,
      }}/>;
    }
    return <FormsFragment path={this.props.path} folderId={this.props.folderId}/>;
  }
}

export type FoldersFragmentProps = PageFragmentProps & {}

type FoldersFragmentState = PageFragmentState & {
  items: SidebarItems,
  selectedItem?: SidebarItem,
}

export class FoldersFragment extends BaseListFragment<Folder, Folders, FoldersFragmentProps, FoldersFragmentState> implements PageWithSidebarContainerRenderer, SidebarListener {

  static nestedPaths(): PathComponent[] {
    return [
      {
        path: "",
        render: pathProps => <FolderFragment path={pathProps} folderId={null}/>,
      },
      {
        path: ":folder_id",
        render: pathProps => <FolderFragment path={pathProps} folderId={pathProps.params.folder_id}/>,
      },
      {
        path: ":folder_id/forms",
        render: pathProps => <FolderFragment path={pathProps} folderId={pathProps.params.folder_id}/>,
      },
      {
        path: ":folder_id/forms/:form_id",
        render: pathProps => <FolderFragment path={pathProps} folderId={pathProps.params.folder_id}
                                           formId={pathProps.params.form_id}/>,
      },
    ];
  }

  private readonly addAction = new Action("Add folder", () => {
    EditFolderHelper.addFolder();
  }, AddOutlined);


  constructor(props: FoldersFragmentProps, context: any) {
    super(props, context, Folders.getInstance());
  }

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    await super.fetchOnMount(forceReload);
    this.setState({
      items: {
        groups: [
          {
            items: this.loader.getListItems().map(folder => ({
              title: folder.title,
              text: folder.description,
              path: folder.id,
              render: pathProps => <FolderFragment folderId={folder.id}/>,
            })),
          },
        ],
        containerId: "folders",
      },
    })
  }

  onItemChanged(item: Folder) {
    this.reload();
  }

  componentDidUpdate(prevProps: Readonly<FoldersFragmentProps>, prevState: Readonly<FoldersFragmentState>, snapshot?: any) {
    super.componentDidUpdate(prevProps, prevState, snapshot);
    if (prevProps.path.params !== this.props.path.params) {
      this.forceUpdate();
    }
  }

  renderContent(): React.ReactElement {
    return <PageWithSidebarContainer renderer={this}/>;
  }

  renderPageWithSidebarContainerContent(): React.ReactElement {
    return <Outlet/>;
  }

  renderPageWithSidebarContainerSidebar(): React.ReactElement {
    return <Sidebar
      title="Folders"
      tabs={[{
        id: "default",
        items: this.state.items,
        selected: this.state.selectedItem,
        listener: this,
        action: this.addAction,
      }]}
    />;
  }

  onSelectionChanged(item: SidebarItem) {
    this.setState({
      selectedItem: item,
    });
  }
}