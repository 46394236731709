import {PageFragment, PageFragmentProps, PageFragmentState} from "../../shared/PageFragment";
import {PageWithSidebarContainer, PageWithSidebarContainerRenderer} from "../../shared/PageWithSidebarContainer";
import React, {ReactElement} from "react";
import {Sidebar, SidebarItem, SidebarItems, SidebarListener} from "../../shared/Sidebar";
import {PathComponent} from "../../index";
import {Typography} from "@mui/material";
import {Outlet} from "react-router-dom";
import {StyledBoxColumn, StyledContainer} from "../../shared/StyledComponents";
import {PD_XLG} from "../../shared/dimens";
import {PlaceholderFragment} from "../../shared/PlaceholderFragment";
import {FormGenContainer} from "../../shared/FormGenContainer";
import {BaseSettings, GeneralSettings, NotificationsSettings, PreferencesSettings, Settings} from "./settings";

abstract class BaseSettingsContentFragment<T extends BaseSettings> extends PageFragment {

  constructor(props: PageFragmentProps, context: any, readonly settings: T) {
    super(props, context);
  }

  renderContent(): React.ReactElement {
    return <StyledContainer>
      <StyledBoxColumn style={{padding: PD_XLG, gap: PD_XLG}}>
        <Typography>{this.getSettingsDescription()}</Typography>
        {this.renderSettingsContent()}
      </StyledBoxColumn>
    </StyledContainer>;
  }

  protected renderSettingsContent(): ReactElement {
    return <FormGenContainer
      autoSave
      onContainerSave={container => this.settings.save()}
      content={this.settings}/>
  }

  protected abstract getSettingsDescription(): string;
}

class GeneralSettingsContentFragment extends BaseSettingsContentFragment<GeneralSettings> {

  constructor(props: PageFragmentProps, context: any) {
    super(props, context, Settings.getInstance().general);
  }

  protected getTitle(): string | null | undefined {
    return "General";
  }

  protected getSettingsDescription(): string {
    return "Manage your form settings";
  }
}

class NotificationsSettingsContentFragment extends BaseSettingsContentFragment<NotificationsSettings> {

  constructor(props: PageFragmentProps, context: any) {
    super(props, context, Settings.getInstance().notifications);
  }

  protected getTitle(): string | null | undefined {
    return "Notifications";
  }

  protected getSettingsDescription(): string {
    return "Manage your form settings";
  }
}


export class PreferencesFragment extends BaseSettingsContentFragment<PreferencesSettings> {

  constructor(props: PageFragmentProps, context: any) {
    super(props, context, Settings.getInstance().preferences);
  }

  protected getTitle(): string | null | undefined {
    return "Preferences";
  }

  protected getSettingsDescription(): string {
    return "Manage your preferences.";
  }
}

const SIDEBAR_ITEMS: SidebarItems = {
  groups: [
    {
      items: [
        {
          title: "General",
          path: "general",
          render: pathProps => <GeneralSettingsContentFragment path={pathProps}/>,
        },
        {
          title: "Notifications",
          path: "notifications",
          render: pathProps => <NotificationsSettingsContentFragment path={pathProps}/>,
        },
      ],
      title: "Overview",
      variant: "condensed",
    },
    {
      items: [
        {
          title: "Profile",
          path: "profile",
          render: pathProps => <PlaceholderFragment path={pathProps}/>,
        },
        {
          title: "Preferences",
          path: "preferences",
          render: pathProps => <PreferencesFragment path={pathProps}/>,
        },
      ],
      title: "Account",
      variant: "condensed",
    },
  ],
  containerId: "settings",
}

export type SettingsFragmentProps = PageFragmentProps & {}

type SettingsFragmentState = PageFragmentState & {
  selectedItem?: SidebarItem,
}

export class SettingsFragment extends PageFragment<SettingsFragmentProps, SettingsFragmentState> implements PageWithSidebarContainerRenderer, SidebarListener {

  static nestedPaths(): PathComponent[] {
    return PageWithSidebarContainer.nestedPathsFromSidebarItems(SIDEBAR_ITEMS);
  }

  renderContent(): ReactElement {
    return <PageWithSidebarContainer renderer={this}/>;
  }

  renderPageWithSidebarContainerContent(): React.ReactElement {
    return <Outlet/>;
  }

  renderPageWithSidebarContainerSidebar(): React.ReactElement {
    return <Sidebar
      title={"Settings"}
      tabs={[{
        id: "default",
        items: SIDEBAR_ITEMS,
        selected: this.state.selectedItem,
        listener: this,
      }]}/>;
  }

  onSelectionChanged(item: SidebarItem) {
    this.setState({selectedItem: item});
  }
}