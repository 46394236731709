import {Form, FORM_STATUS_KTM_COLORS, FORM_STATUS_KTS, Forms, FormStatus} from "./types";
import {TableData} from "../../shared/tabledata";
import {
  BaseSelectTableDataRow,
  GroupingMetadata,
  TableDataContainer,
  TableDataSource
} from "../../shared/TableDataContainer";
import {FabSpec, PageFragment} from "../../shared/PageFragment";
import {TabInfo} from "../../shared/TabsContainer";
import {TableDataSourceFragmentProps, TableDataSourceFragmentState} from "../../shared/TableDataSourceFragment";
import React, {ReactElement} from "react";
import {PD_SM, SZ_XLG} from "../../shared/dimens";
import {EditFormHelper, onManageForm} from "./EditFormHelper";
import {PathComponent, PathProps} from "../../index";
import {FormFragment} from "./FormFragment";
import {
  BaseFormListTableDataSourceFragment,
  BaseFormListTableDataSourceFragmentState
} from "../BaseFormListTableDataSourceFragment";
import {$KTS, Action, ACTION_SEPARATOR, ActionBase, KeyTextStrings} from "../../shared/types";
import {StyledBoxRow, StyledSpan} from "../../shared/StyledComponents";
import {IconButton, Tooltip} from "@mui/material";
import {StringUtil} from "../../shared/string_util";
import {md5} from "../../shared/md5";
import {Grouping} from "../types";
import {AddOutlined, DeleteOutlined, EditOutlined, MoreVertOutlined, PeopleAltOutlined} from "@mui/icons-material";
import {Debouncer} from "../../shared/file_util";
import {Folder, Folders} from "../folders/types";
import {App} from "../App";
import {EditFolderHelper, onManageFolder} from "../folders/EditFolderHelper";

class FormsTableDataRow extends BaseSelectTableDataRow {

  @TableData({name: "Title", type: "tagged_text"})
  title: string;

  @TableData({
    name: "Status",
    type: "enum",
    enumValues: FORM_STATUS_KTS.values,
    enumColors: FORM_STATUS_KTM_COLORS.values,
    cellStyle: {width: SZ_XLG}
  })
  status: string;

  @TableData({
    name: "Favorite",
    cellId: "favorite",
    type: "checkbox",
    checkboxVariant: "heart",
    cellStyle: {width: SZ_XLG}
  })
  favorite: boolean;
}

class FormsTableDataSource implements TableDataSource<Form, FormsTableDataRow> {

  private readonly debouncer = new Debouncer(1000);
  private readonly saveItems = new Map<string, Form>();

  constructor(private readonly pathProps: PathProps) {
  }

  createTableDataRow(): FormsTableDataRow {
    return new FormsTableDataRow();
  }

  applyTableDataToRow(data: Form, content: FormsTableDataRow): void {
    content.title = data.title;
    content.status = data.status;
    content.favorite = data.favorite;
  }

  onTableDataSelected(data: Form) {
    onManageForm(this.pathProps, data);
  }

  onTableCellChanged(data: Form, cellId: string, value: any): void {
    switch (cellId) {
      case "favorite":
        data.favorite = value as boolean;
        break;
    }
    this.saveItems.set(data.id, data);
    this.debouncer.run(() => {
      const forms = Forms.getInstance();
      this.saveItems.forEach(value => {
        forms.addListItem(value);
      });
      this.saveItems.clear();
    })
  }
}

type FormsContentFragmentProps = TableDataSourceFragmentProps & {
  folderId?: string,
  tabType: FormsTabType,
}

type FormsContentFragmentState = BaseFormListTableDataSourceFragmentState & {
  data: Form[],
}

class FormsContentFragment extends BaseFormListTableDataSourceFragment<Form, FormsTableDataRow, Forms, FormsContentFragmentProps, FormsContentFragmentState> {

  private readonly dataSource = new FormsTableDataSource(this.props.path);

  constructor(props: FormsContentFragmentProps, context: any) {
    super(props, context, Forms.getInstance());
  }

  protected async fetchOnMount(): Promise<void> {
    await super.fetchOnMount();
    await this.update();
  }

  onItemChanged(item: Form) {
    this.update();
  }

  componentDidUpdate(prevProps: Readonly<FormsContentFragmentProps>, prevState: Readonly<FormsContentFragmentState>, snapshot?: any) {
    if (prevProps !== this.props) {
      this.update();
    }
  }

  private async update() {
    let data = this.loader.getListItems()
      .filter(item => item.folderId == this.props.folderId)
      .filter(item => {
        switch (this.props.tabType) {
          case FormsTabType.MYFORMS:
            return item.status !== FormStatus.ARCHIVED;
          case FormsTabType.ARCHIVED:
            return item.status === FormStatus.ARCHIVED;
        }
      })
    this.setState({
      title: FORMS_TAB_TYPE_KTS.findText(this.props.tabType),
      data: data,
    });
  }

  renderContent(): ReactElement {
    let groupingFn: (item: Form) => GroupingMetadata;
    switch (this.state.displayOptions?.grouping) {
      case Grouping.NONE:
        groupingFn = null;
        break;
      case Grouping.TAGGED_TEXT: {
        const taggedTextGroups = new Map<string, GroupingMetadata>();
        groupingFn = item => {
          const tags = StringUtil.findTags(item.title);
          const title = tags.join(", ");
          let groupingMetadata = taggedTextGroups.get(title);
          if (!groupingMetadata) {
            groupingMetadata = {
              id: md5(title),
              title: title,
            };
            taggedTextGroups.set(title, groupingMetadata);
          }
          return groupingMetadata;
        }
      }
        break;
    }
    return <TableDataContainer
      groupingFn={groupingFn}
      data={this.state.data}
      source={this.dataSource}
      multiSelectListener={this}/>;
  }

  protected styleFlags(): number {
    return PageFragment.STYLE_TOOLBAR_TYPE_FLAG;
  }
}

enum FormsTabType {
  MYFORMS = "myforms",
  ARCHIVED = "archived",
}

const FORMS_TAB_TYPE_KTS = new KeyTextStrings([
  $KTS(FormsTabType.MYFORMS, "My forms"),
  $KTS(FormsTabType.ARCHIVED, "Archived"),
]);

const TABS: TabInfo<FormsTabType>[] = [
  {
    type: FormsTabType.MYFORMS,
    text: "My forms",
  },
  {
    type: FormsTabType.ARCHIVED,
    text: "Archived",
  },
];

export type FormsFragmentProps = TableDataSourceFragmentProps & {
  folderId?: string,
}

type FormsFragmentState = TableDataSourceFragmentState & {
  folder?: Folder,
}

export class FormsFragment extends PageFragment<FormsFragmentProps, FormsFragmentState> {

  static nestedPaths(): PathComponent[] {
    return [
      {
        path: ":form_id",
        handle: {containerId: "forms", path: ":form_id"},
        render: pathProps => <FormFragment path={pathProps} formId={pathProps.params.form_id}/>,
      },
    ];
  }

  protected getContainerId(): string | null | undefined {
    return "forms";
  }

  protected onCreateState(): FormsFragmentState {
    return {
      ...super.onCreateState(),
      selectedToolbarTabId: FormsTabType.MYFORMS,
    };
  }

  protected styleFlags(): number {
    return PageFragment.STYLE_TOOLBAR_TYPE_FLAG;
  }

  componentDidUpdate(prevProps: Readonly<FormsFragmentProps>, prevState: Readonly<FormsFragmentState>, snapshot?: any) {
    super.componentDidUpdate(prevProps, prevState, snapshot);
    if (prevProps.folderId !== this.props.folderId) {
      this.reload();
    }
  }

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    const folder = await Folders.getInstance().getOrLoadItem(this.props.folderId);
    this.setState({
      folder: folder,
      title: folder?.title,
    })
  }

  getToolbarTabs(): TabInfo<any>[] {
    return TABS;
  }

  createFabSpec(): FabSpec {
    return {
      iconType: AddOutlined,
      variant: "extended",
      text: "New form",
      onClick: () => EditFormHelper.addForm(),
    };
  }

  protected getToolbar(toolbarModeId: string | null): React.ReactElement {
    if (!this.state.folder) {
      return null;
    }
    return <StyledBoxRow style={{flexGrow: 1, alignItems: "center", paddingRight: PD_SM}}>
      <StyledSpan/>
      <Tooltip title={"Share folder"}>
        <IconButton
          onClick={(event) => {
          }}>
          <PeopleAltOutlined/>
        </IconButton>
      </Tooltip>
      <IconButton
        onClick={(event) => this.showFolderOptionsPopover(event.target as HTMLElement)}>
        <MoreVertOutlined/>
      </IconButton>
    </StyledBoxRow>;
  }

  private showFolderOptionsPopover(anchorEl: HTMLElement) {
    const path = this.props.path;
    const folder = this.state.folder;
    const folderOptionsActions: ActionBase[] = [
      new Action("Edit", () => EditFolderHelper.editFolder(this.props.path, folder), EditOutlined),
      ACTION_SEPARATOR,
      new Action("Delete folder", () => {
        App.CONTEXT.showTextDialog("Delete", "Are you sure you want to delete \"" + folder.title + "\". You cannot undo this action.", new Action("Confirm", () => {
          async function deleteAll() {
            (await Forms.getInstance().getOrLoadListItems())
              .filter(item => item.folderId === folder.id)
              .forEach(item => Forms.getInstance().deleteListItemById(item.id));
            await Folders.getInstance().deleteListItemById(folder.id);
            onManageFolder(path, null);
          };
          deleteAll();
        }));
      }, DeleteOutlined).makeDestructive(),
    ];
    App.CONTEXT.showActionsListPopover(anchorEl, null, folderOptionsActions);
  }

  renderContent(): ReactElement {
    return <FormsContentFragment
      tabType={this.state.selectedToolbarTabId as FormsTabType}
      folderId={this.props.folderId}
      path={this.props.path}/>;
  }
}