import {Typography} from "@mui/material";
import {BORDER_RADIUS, PD_XLG, PD_XSM} from "../shared/dimens";
import {Form, FORM_STATUS_KTM_COLORS, FORM_STATUS_KTS} from "./forms/types";
import React from "react";

export function FormStatusView(props: { form: Form }) {
  return <Typography style={{
    overflowX: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    paddingTop: PD_XSM,
    paddingBottom: PD_XSM,
    paddingLeft: PD_XLG,
    paddingRight: PD_XLG,
    color: FORM_STATUS_KTM_COLORS.findMetadata(props.form.status)?.color,
    background: FORM_STATUS_KTM_COLORS.findMetadata(props.form.status)?.background,
    borderRadius: BORDER_RADIUS,
  }}>
    {FORM_STATUS_KTS.findText(props.form.status)}
  </Typography>

}