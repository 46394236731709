import {DialogFragment, DialogFragmentProps, DialogFragmentState, PageFragment} from "../../shared/PageFragment";
import {
  BaseFormAnswerItemData,
  BaseFormQuestionItemData,
  Form,
  FormAnswerIds,
  FormAnswerItem,
  FormAnswerItemChoiceData,
  FormAnswerItems,
  FormAnswerItemTextData,
  FormQuestionItem,
  FormQuestionItemChoiceData,
  FormQuestionItems,
  FormQuestionItemTextData,
  FormQuestionItemType,
  Forms,
  FormStatus
} from "./types";
import React, {Component, createRef, ReactElement, RefObject} from "react";
import {
  StyledBoxColumn,
  StyledBoxRow,
  StyledContainer,
  StyledHorizontalDivider,
  StyledSpan
} from "../../shared/StyledComponents";
import {Button, Card, IconButton, Radio, TextField, Typography} from "@mui/material";
import {PD_MD, PD_XLG, PD_XXLG, SZ_JUMBO, SZ_SSM} from "../../shared/dimens";
import {App} from "../App";
import {Action, ActionBase, ActionGroup, ActionGroupMode} from "../../shared/types";
import {ArrowLeftOutlined, ArrowRightOutlined, ComputerOutlined, PhoneOutlined} from "@mui/icons-material";
import {JSON_OBJECT} from "../../shared/json/helpers";
import {BaseApp, DIALOG_FLAG_DISABLE_BACKDROP_CLICK, DIALOG_FLAG_SHOW_CLOSE} from "../../shared/BaseApp";
import {HtmlFragment} from "../../shared/HtmlFragment";
import {AboutFragment} from "../../shared/AboutFragment";
import fyneappsLogotype from "fyneapps-shared/res/fyneapps_logotype.png";
import {md5_uuid} from "../../shared/md5";
import {FormStatusView} from "../FormStatusView";

type BaseQuestionItemViewProps = {
  questionItem: FormQuestionItem,
  readonly?: boolean,
  answerItem?: FormAnswerItem,
}

abstract class BaseQuestionItemView<T extends BaseFormQuestionItemData, V extends BaseFormAnswerItemData, P extends BaseQuestionItemViewProps = BaseQuestionItemViewProps, S = {}> extends Component<P, S> {

  constructor(props: P, context: any) {
    super(props, context);
    this.state = this.onCreateState();
  }

  protected onCreateState(): S {
    return {} as S;
  }

  render(): ReactElement {
    const data = this.readQuestionItemData();
    return <StyledBoxColumn style={{marginTop: PD_XLG}}>
      <Typography style={{fontSize: "110%"}}>{data.text}</Typography>
      {this.renderDetails()}
    </StyledBoxColumn>;
  }

  protected abstract readQuestionItemData(): T;

  protected abstract renderDetails(): ReactElement;

  abstract writeAnswerItemData(): V;
}

class QuestionItemChoiceView extends BaseQuestionItemView<FormQuestionItemChoiceData, FormAnswerItemChoiceData> {

  protected readQuestionItemData(): FormQuestionItemChoiceData {
    return JSON_OBJECT.deserializeObject(this.props.questionItem.data, FormQuestionItemChoiceData);
  }

  protected renderDetails() {
    return <>
      <StyledBoxRow style={{alignItems: "center"}}>
        <Radio/>
        <Typography>Option 1</Typography>
      </StyledBoxRow>
      <StyledBoxRow style={{alignItems: "center"}}>
        <Radio/>
        <Typography>Option 2</Typography>
      </StyledBoxRow>
    </>;
  }

  writeAnswerItemData(): FormAnswerItemChoiceData {
    return undefined;
  }
}

class QuestionItemTextView extends BaseQuestionItemView<FormQuestionItemTextData, FormAnswerItemTextData, BaseQuestionItemViewProps, {
  text?: string
}> {

  protected onCreateState(): { text?: string } {
    let text;
    const data = this.props.answerItem?.data;
    if (data) {
      text = JSON_OBJECT.deserializeObject(data, FormQuestionItemTextData)?.text;
    }
    return {
      text: text,
    };
  }

  protected readQuestionItemData(): FormQuestionItemTextData {
    return JSON_OBJECT.deserializeObject(this.props.questionItem.data, FormQuestionItemTextData);
  }

  protected renderDetails() {
    return <>
      <TextField
        disabled={this.props.readonly}
        value={this.state.text}
        onChange={event => this.setState({text: event.target.value})}
        size="small" placeholder={"Your answer..."}/>
    </>;
  }

  writeAnswerItemData(): FormAnswerItemTextData {
    const text = this.state.text?.trim();
    if (!text) {
      return null;
    }
    const data = new FormAnswerItemTextData();
    data.text = text;
    return data;
  }
}

export enum ViewMode {
  INPUT,
  PREVIEW,
  ANSWERS,
}

export type ViewFormFragmentProps = DialogFragmentProps & {
  viewMode: ViewMode,
  formId?: string,
}

enum PreviewAs {
  COMPUTER,
  MOBILE,
}

enum AnswerStatus {
  INPUT,
  SAVING,
  SAVED,
  ERROR,
  LOADING,
  VIEW,
}

type ViewFormFragmentState = DialogFragmentState & {
  form: Form,
  questionItems: FormQuestionItem[],
  answerIds?: string[],
  currentAnswerIndex?: number,
  viewAs: PreviewAs,
  answerStatus: AnswerStatus,
  answerItems?: FormAnswerItem[],
}

export class ViewFormFragment extends DialogFragment<ViewFormFragmentProps, ViewFormFragmentState> {

  protected onCreateState(): ViewFormFragmentState {
    return {
      ...super.onCreateState(),
      viewAs: PreviewAs.COMPUTER,
      answerStatus: AnswerStatus.INPUT,
    };
  }

  componentDidUpdate(prevProps: Readonly<ViewFormFragmentProps>, prevState: Readonly<ViewFormFragmentState>, snapshot?: any) {
    super.componentDidUpdate(prevProps, prevState, snapshot);
    if (prevState.currentAnswerIndex !== this.state.currentAnswerIndex) {
      this.loadAnswers();
    }
  }

  private async loadAnswers() {
    this.setState({
      answerStatus: AnswerStatus.LOADING,
    });
    this.setState({
      answerItems: await new FormAnswerItems(this.state.form.id, this.state.answerIds[this.state.currentAnswerIndex]).getOrLoadListItems(),
      answerStatus: AnswerStatus.VIEW,
    });
  }

  protected styleFlags(): number {
    if (this.props.viewMode === ViewMode.INPUT) {
      return 0;
    }
    return PageFragment.STYLE_TOOLBAR_TYPE_FLAG | PageFragment.STYLE_BACK_BUTTON_FLAG;
  }

  onBackButtonClicked() {
    App.CONTEXT.hideAllDialogs();
  }

  private readonly previewModeActions: ActionBase[] = [
    new ActionGroup([
        new Action("Computer", () => {
          this.setState({
            viewAs: PreviewAs.COMPUTER,
          });
        }, ComputerOutlined),
        new Action("Mobile", () => {
          this.setState({
            viewAs: PreviewAs.MOBILE,
          });
        }, PhoneOutlined),
      ],
      "View as",
      ActionGroupMode.SINGLE_SELECT_AUTO),
  ];

  protected getToolbar(toolbarModeId: string | null): React.ReactElement {
    switch (this.props.viewMode) {
      default:
      case ViewMode.INPUT:
        return null;
      case ViewMode.PREVIEW:
        return <StyledBoxRow style={{flexGrow: 1}}>
          {this.renderToolbarButtonsInToolbarContainer(this.previewModeActions)}
          <StyledSpan/>
          {this.renderInToolbarContainer([
            <FormStatusView form={this.state.form}/>,
          ])}
          {/*{this.renderToolbarButtonsInToolbarContainer(getPublishActions(this.state.form))}*/}
        </StyledBoxRow>;
      case ViewMode.ANSWERS:
        return <StyledBoxRow>
          <Typography variant="h6">{(this.state.answerIds?.length || 0) + " Answer(s)"}</Typography>
        </StyledBoxRow>;
    }
  }

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    const formId = this.props.formId || this.props.path.params.form_id;
    if (!formId) {
      return;
    }
    this.setState({
      form: await Forms.getInstance().getOrLoadItem(formId),
      questionItems: await (new FormQuestionItems(formId)).getOrLoadListItems(),
    });
    if (this.props.viewMode === ViewMode.ANSWERS) {
      let answerIds = await new FormAnswerIds(formId).getOrLoadListItems();
      this.setState({
        answerIds: answerIds,
      });
      if ((answerIds?.length > 0)) {
        this.setState({
          currentAnswerIndex: 0,
        });
      }
    }
  }

  renderContent(): ReactElement {
    const form = this.state.form;
    if (!form || (!this.props.formId && form.status !== FormStatus.PUBLISHED)) {
      return this.renderNotFound();
    }
    let rendered = undefined;
    switch (this.state.answerStatus) {
      default:
      case AnswerStatus.INPUT:
        rendered = this.renderContentInput(form);
        break;
      case AnswerStatus.SAVING:
        rendered = this.renderContentSaving(form);
        break;
      case AnswerStatus.SAVED:
        rendered = this.renderContentSaved(form);
        break;
      case AnswerStatus.ERROR:
        rendered = this.renderContentError(form);
        break;
      case AnswerStatus.LOADING:
        rendered = this.renderContentLoading(form);
        break;
      case AnswerStatus.VIEW:
        rendered = this.renderContentView(form);
        break;
    }
    if (!rendered) {
      rendered = this.renderContentError(form);
    }
    const viewSmall = this.state.viewAs === PreviewAs.MOBILE;
    return <StyledBoxColumn style={{position: "relative", flexGrow: 1}}>
      <div style={{
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: form.properties.themeColor,
        opacity: 0.1
      }}/>
      <StyledContainer size={viewSmall ? "xsm" : undefined} style={{
        zIndex: 1,
        gap: PD_XLG,
        paddingTop: this.props.viewMode !== ViewMode.ANSWERS ? PD_XXLG : PD_MD,
        paddingBottom: PD_XXLG
      }}>
        {this.props.viewMode === ViewMode.ANSWERS
          ? this.renderAnswerNavigation()
          : null}
        <Card>
          <StyledBoxColumn>
            <StyledBoxColumn style={{padding: PD_XLG, borderTop: form.properties.themeColor + " 8px solid"}}>
              <Typography variant="h4">{form.title}</Typography>
              <Typography>{form.description}</Typography>
            </StyledBoxColumn>
            <StyledHorizontalDivider/>
            <StyledBoxColumn style={{minHeight: SZ_JUMBO}}>
              {rendered}
            </StyledBoxColumn>
          </StyledBoxColumn>
        </Card>
        <a href="https://forms.fyneapps.com" style={{alignSelf: "center"}}>
          <StyledBoxRow style={{alignItems: "center", gap: 0, opacity: 0.33}}>
            <img src={fyneappsLogotype} style={{height: SZ_SSM, marginBottom: -4}}/>
            <Typography style={{fontSize: "125%", fontWeight: 600}}>Forms</Typography>
          </StyledBoxRow>
        </a>
        <Typography variant="body2">Never submit passwords through Fyneapps Forms. This content is created by the owner
          of the form. The data you submit will be sent to the form owner. Fyneapps is not responsible for the privacy
          or security practices of its customers, including those of this form owner.</Typography>
        <StyledBoxRow style={{alignItems: "center", gap: PD_XLG, padding: PD_MD, justifyContent: "center"}}>
          <Typography variant="caption">
            <a href="#"
               onClick={() => BaseApp.CONTEXT.showDialog(null, () =>
                 <HtmlFragment
                   url="/privacy.html"/>)}>
              Privacy Policy
            </a>
          </Typography>
          <Typography variant="caption">
            <a href="#"
               onClick={() => BaseApp.CONTEXT.showDialog(null, () =>
                 <HtmlFragment
                   url="/terms.html"/>)}>
              Terms
            </a>
          </Typography>
          <Typography variant="caption">
            <a href="#"
               onClick={() => BaseApp.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_DISABLE_BACKDROP_CLICK}, () =>
                 <AboutFragment/>)}>
              About
            </a>
          </Typography>
        </StyledBoxRow>
      </StyledContainer>
    </StyledBoxColumn>;
  }

  private renderContentInput(form: Form): ReactElement {
    return <>
      <StyledBoxColumn style={{padding: PD_XLG, marginTop: -24}}>
        {this.state.questionItems?.map(item => this.renderItem(item))}
      </StyledBoxColumn>
      <StyledBoxRow style={{alignItems: "center", padding: PD_MD}}>
        <StyledSpan/>
        <Button>Clear form</Button>
      </StyledBoxRow>
      <StyledHorizontalDivider/>
      <StyledBoxRow style={{alignItems: "center", padding: PD_MD}}>
        <Button variant="contained"
                style={{backgroundColor: form.properties.themeColor, flexGrow: 1}}
                onClick={() => this.onSubmitAnswers()}>
          Submit
        </Button>
      </StyledBoxRow>
    </>;
  }

  private renderContentView(form: Form): ReactElement {
    return <>
      <StyledBoxColumn style={{padding: PD_XLG, marginTop: -24}}>
        {this.state.questionItems?.map(item => this.renderItem(item, true, this.state.answerItems?.find(answerItem => answerItem.questionItemId === item.id)))}
      </StyledBoxColumn>
    </>;
  }

  private renderContentSaving(form: Form): ReactElement {
    return this.renderContentInDefaultContainer(form, this.renderPleaseWait());
  }

  private renderContentSaved(form: Form): ReactElement {
    return this.renderContentInDefaultContainer(form, <>
      <Typography>Your answer has been recorded, thank you.</Typography>
      {/*<Typography><a href={"https://forms.fyneapps.com"}>Go home</a></Typography>*/}
    </>);
  }

  private renderContentError(form: Form): ReactElement {
    return this.renderContentInDefaultContainer(form, <Typography>Oops. Something went wrong. Please try again
      later.</Typography>);
  }

  private renderContentLoading(form: Form): ReactElement {
    return this.renderContentInDefaultContainer(form, this.renderPleaseWait());
  }

  private renderContentInDefaultContainer(form: Form, element: ReactElement): ReactElement {
    return <StyledBoxColumn style={{flexGrow: 1, alignItems: "center", justifyContent: "center", padding: PD_MD}}>
      {element}
    </StyledBoxColumn>;
  }

  private renderAnswerNavigation(): ReactElement {
    if (!Number.isInteger(this.state.currentAnswerIndex)) {
      return null;
    }
    return <StyledBoxRow style={{alignItems: "center", flexGrow: 1}}>
      <StyledSpan/>
      <IconButton
        disabled={this.state.currentAnswerIndex <= 0}
        onClick={() => this.setState({
          currentAnswerIndex: this.state.currentAnswerIndex - 1,
        })}>
        <ArrowLeftOutlined/>
      </IconButton>
      <Typography>{this.state.currentAnswerIndex + 1} of {this.state.answerIds.length}</Typography>
      <IconButton
        disabled={this.state.currentAnswerIndex >= this.state.answerIds.length - 1}
        onClick={() => this.setState({
          currentAnswerIndex: this.state.currentAnswerIndex + 1,
        })}>
        <ArrowRightOutlined/>
      </IconButton>
      <StyledSpan/>
    </StyledBoxRow>;
  }

  private readonly viewRefs = new Map<string, RefObject<BaseQuestionItemView<any, any>>>();

  private getViewRef(item: FormQuestionItem): RefObject<BaseQuestionItemView<any, any>> {
    let ref = this.viewRefs.get(item.id);
    if (!ref) {
      ref = createRef<BaseQuestionItemView<any, any>>();
      this.viewRefs.set(item.id, ref);
    }
    return ref;
  }

  private renderItem(questionItem: FormQuestionItem, readonly?: boolean, answerItem?: FormAnswerItem): ReactElement {
    switch (questionItem.type) {
      case FormQuestionItemType.CHOICE:
        return <QuestionItemChoiceView
          //@ts-ignore
          ref={this.getViewRef(questionItem)}
          questionItem={questionItem}
          readonly={readonly}
          answerItem={answerItem}/>;
      case FormQuestionItemType.TEXT:
        return <QuestionItemTextView
          //@ts-ignore
          ref={this.getViewRef(questionItem)}
          questionItem={questionItem}
          readonly={readonly}
          answerItem={answerItem}/>;
    }
    return null;
  }

  private onSubmitAnswers() {
    const answerId = md5_uuid();
    const result = this.state.questionItems?.map(item => this.saveItem(item, answerId));
    this.setState({
      answerStatus: AnswerStatus.SAVING,
    });
    setTimeout(() => {
      Promise.all(result)
        .then(() => this.setState({
          answerStatus: AnswerStatus.SAVED,
        }))
        .catch(() => this.setState({
          answerStatus: AnswerStatus.ERROR,
        }));
    }, 1000);
  }

  private async saveItem(item: FormQuestionItem, answerId: string): Promise<void> {
    const data = this.getViewRef(item).current?.writeAnswerItemData();
    if (data) {
      const answerItems = new FormAnswerItems(item.formId, answerId);
      const answerItem = new FormAnswerItem(item.id, null, null, item.formId, item.id, item.type);
      answerItem.data = JSON_OBJECT.serializeObject(data);
      await answerItems.addListItem(answerItem);
    }
  }
}